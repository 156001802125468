import queryString from 'query-string'


// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}


export function paramsListToObject(paramsList: any) {
  var ret: any = {};
  paramsList.forEach(function (entry: any) {
    const key = entry[0];
    const value = entry[1];
    if (key in ret) {
      console.log("FOund duplicate key: " + key);
      var currenEntry = ret[key];
      if (typeof currenEntry === "string") {
        console.log("entry was string");
        var arrayEntry = [currenEntry, value];
        ret[key] = arrayEntry;
      }
      else {
        console.log("entry was array");
        currenEntry.push(value);
      }
    }
    else {
      ret[key] = value;
    }
  })
  return ret;
}

export function convertFiltersToQueryString(filters: any) {
  if (filters) {
    const filterEntries = Object.entries(filters).map(
      ([key, value]) => {
        var tag = "{!tag=" + key + "}";
        if (value instanceof Array) {
          var filterValAsString = value.map((element: string, index: number, arr) => {
            var ret = ""
            if (index === 0) {
              ret = ret + "(";
            }
            if (index > 0) {
              ret = ret + " OR ";
            }
            ret = ret + "\"" + element + "\"";
            if (index === arr.length - 1) {
              ret = ret + ")"
            }
            return ret;
          })
            .join("");
          const ret: any = ["fq", tag + key + ":" + filterValAsString];
          return ret;
        }
        else {
          const ret: any = ["fq", tag + key + ":(\"" + value + "\")"];
          return ret;
        }
      }
    );
    return "&" + queryString.stringify(paramsListToObject(filterEntries));
  }
  else {
    return "";
  }
}



export function convertFiltersToURLStringForBrowser(filters:any){
  //console.log("inside convertFiltersToURLStringForBrowser" + JSON.stringify(filters));

  if(filters){
    const filterEntries = Object.entries(filters).map(
      ([key,value])=>{
        return ["f." + key , value];
      }
    );
    const filterEntriesObj = Object.fromEntries(filterEntries);
    return "&" + queryString.stringify(filterEntriesObj);
  }
  else{
    return "";
  }
}


export function convertPriceToURLStringForBrowser(price:string){
  console.log("inside convertPriceToURLStringForBrowser")
  if(price){
    console.log("returning price in convert " + price)
    return "&price=" + price 
  }
  else{
    console.log("returning price in convert line 100 " )

    return "";
  }
}