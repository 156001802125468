import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectBrowserSearchAppLocation,
  setFilter,
  Param,
  browseCategoryPath,
  searchCategoryPath,
  setPrice
} from './searchSlice';
import {
  useLocation
} from "react-router-dom";
import { Icon } from '@iconify/react';

function FacetDropdownItem(props: any) {
  const fusionFilters = useAppSelector(selectBrowserSearchAppLocation).filters;
  const dispatch = useAppDispatch();

  var facetName = props.facetName;
  var facetValue = props.facetValue;
  var checked = facetValue.checked;
  var multiselect = props.multiselect;
  var name = props.facetValue.name;

  var display = (facetValue.label)
    ? facetValue.label + " (" + facetValue.count + ")"
    : name + " (" + facetValue.count + ")";

  const handleCheckboxClick = (e: any) => {
    e.preventDefault();

    var filterValuesForGivenKey: any[] = [];
    if (fusionFilters[props.facetName] != null && fusionFilters[facetName] instanceof Array) {
      filterValuesForGivenKey = Array.from(fusionFilters[facetName]);
    }
    else if (fusionFilters[props.facetName] != null && typeof (fusionFilters[facetName]) == "string") {
      filterValuesForGivenKey = [fusionFilters[facetName]];
    }

    const filterValueExists = filterValuesForGivenKey.includes(name);
    if (!filterValueExists) {
      if (multiselect) {
        filterValuesForGivenKey.push(name);
        const param: Param = {
          name: props.facetName,
          value: filterValuesForGivenKey
        }
        dispatch(setFilter(param));
      }
      else {
        const param: Param = {
          name: props.facetName,
          value: [name]
        }
        dispatch(setFilter(param));
      }
    }
    else {

      //a new array with the facetValue has been removed from original array
      const newArrayWithoutFacetValue = filterValuesForGivenKey.filter(function (item) {
        return item !== name
      })

      const param = {
        name: props.facetName,
        value: newArrayWithoutFacetValue
      }
      dispatch(setFilter(param));
    }
  };
  return (
    <li>
      {checked

        ? <input type="checkbox" checked={true} onChange={handleCheckboxClick} />
        : <input type="checkbox" checked={false} onChange={handleCheckboxClick} />
      }

      <a href="/" onClick={handleCheckboxClick}>{display}</a>
      <span className="checkmark"></span>
    </li>
  )
}


function showDisplayFacet(facetName: string) {
const labels: any = {
    searchedBrand_text_en_s: "Brand",
    dsf_category_l1: "Category",
    as_Size_en_string_s: "Size",
    dsf_gender: "Gender"
  }

  if (labels[facetName]) {
    return labels[facetName];
  }



  return facetName.replace("as_", "").replace("_ss", "").replaceAll("_", " ");
}

function CategoryFacetDropdownItemForBrowse(props: any) {
  const dispatch = useAppDispatch();
  var facetValue = props.facetValue;
  var name = props.facetValue.name;

  var display = (facetValue.label)
    ? facetValue.label + " (" + facetValue.count + ")"
    : name + " (" + facetValue.count + ")";

  const handleCategoryClick = (facetValue: any) => (e: any) => {
    e.preventDefault();
    const param = {
      name: "pathname",
      value: facetValue.url
    }
    dispatch(browseCategoryPath(param));
  };
  return (
    <li>
      <a href="/" onClick={handleCategoryClick(facetValue)} className="singleselect">{display}</a>
    </li>
  )
}


function CategoryFacetDropdownItemForSearch(props: any) {
  const dispatch = useAppDispatch();
  var facetValue = props.facetValue;
  var name = props.facetValue.name;

  var display = (facetValue.label)
    ? facetValue.label + " (" + facetValue.count + ")"
    : name + " (" + facetValue.count + ")";

  const handleCategoryClick = (facetValue: any) => (e: any) => {
    e.preventDefault();
    const param = {
      name: "pathname",
      value: "/search" + facetValue.url
    }
    dispatch(searchCategoryPath(param));
  };
  return (
    <li>
      <a href="/" onClick={handleCategoryClick(facetValue)} className="singleselect">{display}</a>
    </li>
  )
}

function FacetField(props: any) {
  var multiselect = (props.facetName === "category") ? false : true;
  const facetValues: any = props.facetValues;
  const facetName = props.facetName;
  const solrResponse = props.solrResponse;
  const queryFacetWeightsMaps = (solrResponse.b2b && solrResponse.b2b.query_facet_weights) ? solrResponse.b2b.query_facet_weights : {}
  const userFacetWeightsMap = (solrResponse.b2b && solrResponse.b2b.user_facet_weights) ? solrResponse.b2b.user_facet_weights : {}
  const isInQueryFacetWeights = queryFacetWeightsMaps[facetName] ? true : false;
  const isInUserFacetWeights = userFacetWeightsMap[facetName] ? true : false;
  const isDynamicFacetBoost = isInQueryFacetWeights || isInUserFacetWeights;

  var checkedFacetValues = facetValues.filter((item:any)=>{return item.checked});
  var isSelected = checkedFacetValues.length > 0;
  const [show, setShow] = useState(props.index < 5);

  const handleShowHideButton = (e: any) => {
    e.preventDefault();
    setShow(!show);
  }
  return (
    props.facetValues.length < 1
      ? null
      : (
        <React.Fragment>
          <div className="widget_list widget_categories">
            <div>
              {(show || isSelected)
                ? <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-down" width="25" /></a>
                : <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-right" width="25" /></a>
              }
              <span className="facet_name">{showDisplayFacet(props.facetName)}</span>

              {
                (isDynamicFacetBoost)
                  ? <div className="facet_boost "><Icon icon="mdi:arrow-up-bold-box" color="rgb(136, 214, 235)" width="28" /></div>
                  : ""
              }
            </div>
            {(show || isSelected)
              ? <><ul>
              {facetValues.slice(0, 10).map((facetValue: any) => (
                <FacetDropdownItem
                  key={facetValue.name}
                  facetName={props.facetName}
                  facetValue={facetValue}
                  multiselect={multiselect}
                />
              ))}
            </ul></>
              : <></>
            }
          </div>
        </React.Fragment>
      )
  );
}



function InlinCategoryBreadcrumbItem(props: any) {
  const crumb = props.crumb;
  const dispatch = useAppDispatch();

  const handleCategoryBreadcrumbClick = (e: any) => {
    e.preventDefault();
    const param = {
      name: "pathname",
      value: "/search" + crumb.url
    }
    dispatch(searchCategoryPath(param));
  };

  return (
    <React.Fragment>
      {
        (!crumb.current)
          ? <li><a href="/" className="singleselect" onClick={handleCategoryBreadcrumbClick}> &lt; {crumb.name}</a></li>
          : <li className="currentCategory">{crumb.name}</li>
      }
    </React.Fragment>
  )
}

function InlinCategoryBreadcrumbList(props: any) {
  const breadcrumbResponse = props.breadcrumbResponse;
  return (
    <React.Fragment>
      {
        breadcrumbResponse.map((crumb: any, index: number) => {
          return <InlinCategoryBreadcrumbItem index={index} crumb={crumb} breadcrumbResponse={breadcrumbResponse} />
        })
      }
    </React.Fragment>
  )
}


function InlinCategoryBreadcrumb(props: any) {
  const breadcrumbResponse = props.breadcrumbResponse;
  const dispatch = useAppDispatch();

  const handleShowAllCategoriesClick = (e: any) => {
    e.preventDefault();
    const param = {
      name: "pathname",
      value: "/search"
    }
    dispatch(searchCategoryPath(param));
  };

  return (
    <React.Fragment>
      {
        (breadcrumbResponse.length === 0)
          ? null
          : (breadcrumbResponse.length === 1)
            ? <li><a href="/" className="singleselect" onClick={handleShowAllCategoriesClick}> &lt; Show All Categories</a></li>
            : <InlinCategoryBreadcrumbList breadcrumbResponse={breadcrumbResponse} />
      }
    </React.Fragment>
  )
}

function CategoryFacet(props: any) {
  var multiselect = (props.facetName === "category") ? false : true;
  const facetValues: any = props.facetValues;
  const location = useLocation();
  const pathname = location.pathname;
  const pageType = (pathname.startsWith("/search")) ? "search" : "browse";
  const solrResponse = props.solrResponse;
  const breadcrumbResponse = (solrResponse.b2b && solrResponse.b2b.breadcrumb) ? solrResponse.b2b.breadcrumb : []
  const facetName = props.facetName;
  const [show, setShow] = useState((props.index > 5));

  const handleShowHideButton = (e: any) => {
    e.preventDefault();
    setShow(!show);
  }

  return (

    <React.Fragment>
      <div className="widget_list widget_categories">
        <div className="facet_name">
          {show
            ? <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-right" width="25" /></a>
            : <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-down" width="25" /></a>
          }

          {showDisplayFacet(facetName)}</div>

        {show
          ? <></>
          : <ul>
            <InlinCategoryBreadcrumb breadcrumbResponse={breadcrumbResponse} />
            {facetValues.slice(0, 10).map((facetValue: any) => (
              (pageType === "browse")
                ? <CategoryFacetDropdownItemForBrowse
                  key={facetValue.name}
                  facetName={props.facetName}
                  facetValue={facetValue}
                  multiselect={multiselect}
                />
                : <CategoryFacetDropdownItemForSearch
                  key={facetValue.name}
                  facetName={props.facetName}
                  facetValue={facetValue}
                  multiselect={multiselect}
                />
            ))}
          </ul>
        }

      </div>
    </React.Fragment>
  );
}

export interface FacetValue {
  name: string;
  count: number;
  label?: string;
  url?: string;
  checked: boolean;
  filter: string;
}



function PriceFacetDropdownItem(props: any) {
  const price = useAppSelector(selectBrowserSearchAppLocation).price;
  const priceExists = (price !== "");

  const dispatch = useAppDispatch();

  var facetName = props.facetName;
  var facetValue = props.facetValue;
  var checked = priceExists;
  var multiselect = props.multiselect;
  var name = props.facetValue.name;
  var display = (facetValue.label) 
    ? facetValue.label + " (" + facetValue.count + ")"
    : name + " (" + facetValue.count + ")";

  const handleCheckboxClick = (e:any) => {
    e.preventDefault();

    var filterValuesForGivenKey: any[] = [];
   

    if (!priceExists) {
      
        const param = {
          name: props.facetName,
          value: facetValue.filter
        }
        dispatch(setPrice(param));
    }
    else {

      //a new array with the facetValue has been removed from original array
      const newArrayWithoutFacetValue = filterValuesForGivenKey.filter(function (item) {
          return item !== name
      })

      const param = {
        name: props.facetName,
        value: ""
      }

      dispatch(setPrice(param));
  }
  };
  return (
    <li>
    <a href="/" className="singleselect" onClick={handleCheckboxClick}>{display} {
      (checked)
      ? <>
      <Icon icon="teenyicons:x-small-outline" />

      </>
      : <></>
      }
    </a>
    </li>
  )
}

function PriceFilter(props: any) {
  const solrResponse = props.solrResponse;

  const facetQueries = (solrResponse && solrResponse.facet_counts && solrResponse.facet_counts.facet_queries) ? solrResponse.facet_counts.facet_queries : {};
  const priceFacetQueryValues = []
  var facetName="Price"
  const [show, setShow] = useState(props.index < 5);
  const isSelected = false;


  for (var key of Object.keys(facetQueries)) {
    var [label,filter] = key.split("|")
    var filterValue = (filter) ? filter : "";
    if(filterValue !== ""){
      const index = filterValue.indexOf("}");
      filterValue = filterValue.substring(0, index+1) + "${effective_price_formula}"
    }
    const facetValueObj: FacetValue = {
      name: label.replaceAll("_"," "),
      count: facetQueries[key],
      checked: false,
      filter: filterValue
    }
    priceFacetQueryValues.push(facetValueObj)
  }

  const priceFacetQueryValuesFiltered = priceFacetQueryValues.filter((facetValue)=>{return facetValue.count > 0})

  const handleShowHideButton = (e: any) => {
    e.preventDefault();
    setShow(!show);
  }
  return(
    <>
    
    {
      priceFacetQueryValuesFiltered.length < 1
      ? null
      : (
        <React.Fragment>
          <div className="widget_list widget_categories">
          <div>
              {(show || isSelected)
                ? <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-down" width="25" /></a>
                : <a href="/" onClick={handleShowHideButton}><Icon icon="material-symbols:keyboard-arrow-right" width="25" /></a>
              }
              <span className="facet_name">{showDisplayFacet(facetName)}</span>
            </div>
            {(show)
            ?<><ul>
            {priceFacetQueryValuesFiltered.slice(0, 10).map((facetValue: any) => (
              <PriceFacetDropdownItem
              key={facetValue.name}
              facetName={props.facetName}
              facetValue={facetValue}
              multiselect={false}
            />
            ))}
          </ul></>
            : <></>}
          </div>
        </React.Fragment>
      )
    }
    </>
  )
}

export function LeftFilters(props: any) {
  const solrResponse = props.solrResponse;
  const facetCounts = (solrResponse && solrResponse.facet_counts)
    ? solrResponse.facet_counts
    : {}
  const facetFields = (facetCounts && facetCounts.facet_fields)
    ? facetCounts.facet_fields
    :
    {
    };

  const facetFieldsKeys = []
  var facetFieldsMap: any = {}

  const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
  const fusionFilters: any = browserSearchAppLocation.filters;

  const location = useLocation();
  const unique_location_key = location.pathname + location.search;


  for (var key of Object.keys(facetFields)) {
    facetFieldsKeys.push(key)
    var facetArray: any = [];
    var facet = facetFields[key];
    //build facet values object that comprises facetField name and count 
    for (var i = 0; i < facet.length; i++) {
      const facetValue = facet[i];

      const facetName = facetValue[0];
      var filterValuesForGivenKey = (fusionFilters[key]) ? fusionFilters[key] : [];
      var filterValueExists = false;
      if (typeof (filterValuesForGivenKey) === "string") {
        filterValueExists = (filterValuesForGivenKey === facetName)
      }
      else {
        filterValueExists = (filterValuesForGivenKey.includes(facetName))
      }

      const facetValueObj: FacetValue = {
        name: facetName,
        count: facetValue[1],
        checked: filterValueExists,
        filter: ""
      }

      if (facetValue.length >= 3) {
        facetValueObj.label = facetValue[2];
      }
      if (facetValue.length >= 4) {
        facetValueObj.url = facetValue[3];
      }

      facetArray.push(facetValueObj);
    }
    facetFieldsMap[key] = facetArray;
  }

  /*
  We expect the category to always be the first.  If it's not 
  the first element, it's likely because the user has filtered or drilled down
  to the leaf, and the response from Fusion no longer contains any more 
  filters to further drill down.  We'll forcibly insert it anyway to be
  able to display an inline breadcrumb to link back up the category tree
  */

  if (facetFieldsKeys[0] !== "category") {
    facetFieldsKeys.unshift("category")
    facetFieldsMap["category"] = []
  }

  return (
    <React.Fragment>
      <div className="col-lg-3 col-md-12">
        <aside className="sidebar_widget">
          <div className="widget_inner">

            {
              facetFieldsKeys.map((key, index) =>

                (key === "category") ?
                  <>
                  <CategoryFacet
                    key={"facetfield-" + unique_location_key + '-' + key}
                    facetName={key}
                    facetValues={facetFieldsMap[key]}
                    solrResponse={solrResponse}
                  />
                  <PriceFilter solrResponse={solrResponse} index={1}/>
                  </>                  :
                  <><FacetField key={"facetfield-" + unique_location_key + '-' + key} facetName={key}
                    facetValues={facetFieldsMap[key]} solrResponse={solrResponse}
                    index={index}
                  /></>
              )
            }
          </div>
        </aside>
      </div>
    </React.Fragment>
  )
}
export default LeftFilters
