import React from 'react';
import FusionRender from './FusionRender';
import {
    useRouteMatch
} from "react-router-dom";
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from '../../react-chatbot/config'
import MessageParser from '../../react-chatbot/MessageParser'
import ActionProvider from '../../react-chatbot/ActionProvider'
import { Drawer } from '@mui/material';
import { Icon } from '@iconify/react';


export function ProductDetailPage(props: any) {
    const matchParams: any = useRouteMatch().params;
    const productId = matchParams.id;
    const additionalParams = {
        q: productId
    }
    const [showChatBot, setShowChatBot] = React.useState(false);

    const handleChatClose = (e: any) => {
        e.preventDefault();
        setShowChatBot(false);
    }
    const handleChatOpen = (e: any) => {
        e.preventDefault();
        setShowChatBot(true);
    }
    return (
        <React.Fragment>
            <FusionRender templateTypes={["item-detail"]} additionalParams={additionalParams} />
            <button onClick={handleChatOpen} id="fixedbutton-chatgpt" style={{ display: "block" }}>
                <Icon icon="logos:openai-icon" width={30} />  AI Virtual Assistant</button>
            <Drawer
                anchor='right'
                open={showChatBot}
                variant='persistent'
            >
                
                <div className='chatbot_close'>
                    <a href="/" onClick={handleChatClose} className="no_decoration"><span><Icon icon="zondicons:close-outline" width={30}/> Close Chat</span></a>
                </div>
                <Chatbot
                    config={config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
            </Drawer>

        </React.Fragment>
    )
}
export default ProductDetailPage