import React from 'react';

export function SwitchOptionNoRedux(props:any) {
  const toggle = props.toggle;
  const setToggle = props.setToggle;

  const handleInputChange = (event:any) => {
    const newValue = !toggle ;
    setToggle(newValue);
  };


  return (
    <React.Fragment>
      <input className="form-check-input" type="checkbox" checked={toggle} onChange={handleInputChange}/>
    </React.Fragment>
  )
}
export default SwitchOptionNoRedux