import { createChatBotMessage } from 'react-chatbot-kit';
import { Icon } from '@iconify/react';

const config = {
  initialMessages: [createChatBotMessage(`Hi there!  I'm happy to answer any questions you have about this product.`)],
    // Defines an object of custom components that will replace the stock chatbot components.
    customComponents: {
     header: () => <div></div>,
     botAvatar: (props) => <><Icon icon="logos:openai-icon" width={20}/><Icon icon="bx:bot" width={40}/></>

   },
};

export default config;