import React, { useState } from 'react';
import { useMegaMenuEndpointQuery } from './FusionAPI'
import { selectUser } from './searchSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    browseMenuPath
} from './searchSlice';
import { Icon } from '@iconify/react';


import { Menu, MenuItem } from "@material-ui/core";

import NestedMenuItem from "material-ui-nested-menu-item";


function NestedMenu(props: any) {
    const data = props.data;
    const menuPosition = props.menuPosition;
    const setMenuPosition = props.setMenuPosition;
    const dispatch = useAppDispatch();
    const depthLimit = (props.depthLimit != null) ? props.depthLimit : 100; 
    const depthLevel = (props.depth != null) ? props.depth : 0;

    const handleItemClick = (menuItemNode: any) => (event: React.MouseEvent) => {
        setMenuPosition(null);
        const param = {
            name: "pathname",
            value: menuItemNode.url
        }
        dispatch(browseMenuPath(param));
    };

    return (
        <React.Fragment>
            <MenuItem onClick={handleItemClick}></MenuItem>


            <NestedMenuItem
                label={data.name}
                parentMenuOpen={!!menuPosition}
                onClick={handleItemClick(data)}
            >
                {
                    data?.children.map((child: any, index: number) => {
                        if (child.children.length > 0 &&  depthLevel < depthLimit) {
                            return <NestedMenu key={child.name} data={child} menuPosition={menuPosition} setMenuPosition={setMenuPosition} depth={depthLevel + 1 } depthLimit={depthLimit}  />
                        }
                        else {
                            return <MenuItem key={child.name} onClick={handleItemClick(child)}>{child.name}</MenuItem>
                        }
                    })
                }
            </NestedMenuItem>
        </React.Fragment>
    )

}


function MenuDropdown(props: any) {
    const data = props.data;
    const dispatch = useAppDispatch();
    const [menuPosition, setMenuPosition] = useState<any>(null);
    const depthLimit = (props.depthLimit != null) ? props.depthLimit : 100; 
    const depthLevel = (props.depth != null) ? props.depth : 0;
    const name = props.name;
    const label = (name != null) ? name : data?.name;

    const menuExpandCollapseToggle = (event: any) => {
        event.preventDefault();
        const currentTargetRect = event.currentTarget.getBoundingClientRect();

        if (menuPosition) {
            setMenuPosition(null);
            return;
        }
        setMenuPosition({
            top: currentTargetRect.top + 40,
            left: currentTargetRect.left + 10
        });
    };

    const handleItemClick = (menuItemNode: any) => (event: React.MouseEvent) => {
        setMenuPosition(null);
        const param = {
            name: "pathname",
            value: menuItemNode.url
        }
        dispatch(browseMenuPath(param));
    };


    return (
        <React.Fragment>
            <li><a href="/" onClick={menuExpandCollapseToggle} >{label} <i className="fa fa-angle-down"></i></a>
                <Menu
                    open={!!menuPosition}
                    onClose={() => setMenuPosition(null)}
                    anchorReference="anchorPosition"
                    anchorPosition={menuPosition}
                >
                    {
                        data?.children.map((child: any, index: number) => {

                            if (child.children.length > 0 && depthLevel < depthLimit) {
                                return <NestedMenu key={child.name} data={child} menuPosition={menuPosition} setMenuPosition={setMenuPosition}  depth={depthLevel + 1 } depthLimit={depthLimit}/>
                            }
                            else {
                                return <MenuItem  key={child.name}  onClick={handleItemClick(child)}>{child.name}</MenuItem>
                            }
                        })
                    }

                </Menu>
            </li>
        </React.Fragment>
    )
}


function findByName(menu: any, name: String) {
    if(menu != null){
        const filtered = menu.children.filter((menu: any)=> menu.name === name)
        return filtered[0];
    }
    else{
        return null;
    }
}


export function MegaMenu(props: any) {
    // If isRerankEnable = true, the megamenu will be reranked using the rerank query profile.
    const [isRerankEnable, setIsRerankEnable] = useState(false);
    const user: any = useAppSelector(selectUser);
    const queryParm: any = {
        user: user,
    }

    if (isRerankEnable) {
        queryParm.rerank = true;
    }

    const { data } = useMegaMenuEndpointQuery(queryParm);
    let shopAll;
    if (data != null) {
        if (isRerankEnable) {
            shopAll = data.b2b.menuRerank;
        } else {
            shopAll = data.b2b.menu;
        }
    }

    const absorbentMats =  findByName(shopAll, "Absorbent Mats");
    const spillKits = findByName(shopAll, "Spill Kits")
    const spillContainment = findByName(shopAll, "Spill Containment")
    const drainProtection = findByName(shopAll, "Drain Protection & Stormwater Management")
    const absorbentsAndWipers = findByName(shopAll, "Absorbents & Wipers")

    function handleRerankClick(e:any) {
        e.preventDefault()
        setIsRerankEnable(!isRerankEnable);
    }

    return (
        <React.Fragment>
            {
                (data != null)
                    ? <React.Fragment>
                        <div className="header_bottom mb-0 sticky-header">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="main_menu header_position">
                                            <nav>
                                                <ul>
                                                    <MenuDropdown data={shopAll} name="Shop All" depthLimit={1}/>
                                                    <MenuDropdown data={absorbentMats} depthLimit={0}/>
                                                    <MenuDropdown data={absorbentsAndWipers} depthLimit={0}/>
                                                    <MenuDropdown data={spillKits} depthLimit={0}/>
                                                    <MenuDropdown data={spillContainment} depthLimit={0}/>
                                                    <MenuDropdown data={drainProtection} name="Drain Protection" depthLimit={0}/>
                                                    {
                                                        (isRerankEnable 
                                                        ?<a href="/"  onClick={handleRerankClick}><Icon icon="fluent:text-grammar-wand-20-regular" width="25" color='green' /> </a> 
                                                        :<a href="/" onClick={handleRerankClick}><Icon icon="fluent:text-grammar-wand-20-regular" width="25" color='black' /> </a>
                                                        )
                                                    }

                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : <React.Fragment />
            }
        </React.Fragment>
    )
}
export default MegaMenu