import React from "react";
import {
    Link
  } from "react-router-dom";

export function Footer() {
    const style_footer = {
        display: "block"
    }
  return (
    <React.Fragment>
      <footer className="footer_widgets">
        <div className="container">
            <div className="footer_top">
                <div className="row">
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="widgets_container widget_menu">
                            <h3>Information</h3>
                            <div className="footer_menu">
                                <ul>
                                    <li><Link to="/relevance">Relevance</Link></li>
                                    <li><a href="/">Delivery Information</a></li>
                                    <li><a href="privacy-policy.html">privacy policy</a></li>
                                    <li><a href="coming-soon.html">Coming Soon</a></li>
                                    <li><a href="/">Terms & Conditions</a></li>
                                    <li><a href="/">Returns</a></li>
                                    <li><a href="/">Gift Certificates</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="widgets_container widget_menu">
                            <h3>Extras</h3>
                            <div className="footer_menu">
                                <ul>
                                    <li><a href="/">Returns</a></li>
                                    <li><a href="/">Order History</a></li>
                                    <li><a href="wishlist.html">Wish List</a></li>
                                    <li><a href="/">Newsletter</a></li>
                                    <li><a href="/">Affiliate</a></li>
                                    <li><a href="/">Specials</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="widgets_container">
                            <h3>Newsletter Subscribe</h3>
                            <p>We’ll never share your email address with a third-party.</p>
                            <div className="subscribe_form">
                                <form id="mc-form" className="mc-form footer-newsletter">
                                    <input id="mc-email" type="email" placeholder="Enter you email address here..." />
                                    <button id="mc-submit">Subscribe</button>
                                </form>
                                <div className="mailchimp-alerts text-centre">
                                    <div className="mailchimp-submitting"></div>
                                    <div className="mailchimp-success"></div>
                                    <div className="mailchimp-error"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </footer>
    </React.Fragment>
  )
}
export default Footer