import React from "react";
import FusionRender from "./FusionRender";
import {
    useLocation
  } from "react-router-dom";

export function Content(props: any) {
    const location = useLocation();
    const pathName = location.pathname;
    var additionalParams:any = {}
    if(pathName !== "/search" &&  pathName !== "/content"){
        //dditionalParams.cat = pathName.replace("/search","");
    }

    return (
        <React.Fragment>
            <FusionRender templateTypes={["content"]} additionalParams={additionalParams}/>
        </React.Fragment>
    )
}
export default Content