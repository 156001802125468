// in ActionProvider.jsx
import React from 'react';
import {
  useRouteMatch
} from "react-router-dom";
import configData from "../system_config.json"
import { getCurrentEnvironment } from "../features/search/EnvironmentConfiguration";
import axios from 'axios';
import {
  setPDPCoPilot
} from '../features/search/searchSlice';
import { useAppDispatch } from '../app/hooks';


const configDataOfAnyType = JSON.parse(JSON.stringify(configData));

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const matchParams = useRouteMatch().params;
  const productId = matchParams.id;
  const fusionBaseURL = configDataOfAnyType[getCurrentEnvironment()].fusion.url;
  const username = configDataOfAnyType[getCurrentEnvironment()].fusion.username;
  const password = configDataOfAnyType[getCurrentEnvironment()].fusion.password;
  const coPilotURL = fusionBaseURL + "/api/apps/B2B/query/B2B_product_detail_copilot" 
  const dispatch = useAppDispatch();

  const handleDetailPageCoPilot = (message) => {
    const axiosParams = {
      url: coPilotURL,
      method: 'get',
      params: {
        product_id: productId,
        q: message
      },
      auth: {
        username: username,
        password: password
      }
    };

    axios(axiosParams) 
    .then((res) => res.data)
    .then((result) => {
      dispatch(setPDPCoPilot(result.b2b));
      const botMessage = createChatBotMessage(result.b2b.llm_response.message) ;
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    })
    .catch((err) => {
      console.error("Error occurred in querying Fusion: " + err.message);
      const errorMessage = "There was an error " + err.message + ". Please try again later.";
      const botMessage = createChatBotMessage(errorMessage) ;
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    });
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleDetailPageCoPilot,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;