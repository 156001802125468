import React, { useState } from 'react';

import { SingleValue } from "react-select";
import FusionFieldDropdown from "./FusionFieldDropdown";
import Slider from '@mui/material/Slider';
import {
  modifyRelevanceRule, RelevanceRuleChangeParam, deleteRelevancyRule
} from '../searchSlice';
import { useAppDispatch } from '../../../app/hooks';
import { BoostFunctionState } from '../custom_types';
import { Icon } from '@iconify/react';

interface DropdownOption {
  readonly value: string;
  readonly label: string;
}

export function BoostFunction(props:any) {
  const dispatch = useAppDispatch();
  const [boostFunctionState, setBoostFunctionState] = useState<BoostFunctionState>(props.rule);


  const handleBoostChange = (event: Event, newValue: number | number[]) => {
    const newBoostFunctionState = {...boostFunctionState}
    newBoostFunctionState.boost = newValue as number;
    setBoostFunctionState(newBoostFunctionState);

    const param: RelevanceRuleChangeParam = {
      id: newBoostFunctionState.id,
      ruleState: newBoostFunctionState
    }
    dispatch(modifyRelevanceRule(param));

  };

  const handleFieldSelectionDropdownChange = (selectedOption:SingleValue<DropdownOption>)=>{
    if(selectedOption  != null ) {
      const newBoostFunctionState = {...boostFunctionState}
      newBoostFunctionState.fieldName = selectedOption.value;
      setBoostFunctionState(newBoostFunctionState);

      const param: RelevanceRuleChangeParam = {
        id: newBoostFunctionState.id,
        ruleState: newBoostFunctionState
      }
      dispatch(modifyRelevanceRule(param));
    }
  };

  const [toggle, setToggle] = useState(props.rule.active);


  const handleEnableDisable = (e:any)=>{
    const newValue = !toggle ;
    setToggle(newValue);


    const newBoostFunctionState = {...boostFunctionState}
    newBoostFunctionState.active = newValue;
    setBoostFunctionState(newBoostFunctionState);

    const param: RelevanceRuleChangeParam = {
      id: newBoostFunctionState.id,
      ruleState: newBoostFunctionState
    }
    dispatch(modifyRelevanceRule(param));
  };

  const handleDeleteClick = (e:any) =>{
    e.preventDefault();
    const param: RelevanceRuleChangeParam = {
      id: boostFunctionState.id,
      ruleState: boostFunctionState
    }
    dispatch(deleteRelevancyRule(param));
  }


  return (
    <React.Fragment>
        <div className="row py-1">
          <div className="col-2">
            <div className="form-check form-switch">
                <a href="/" onClick={handleDeleteClick}><Icon icon="carbon:subtract-alt" width="25" /></a>
                Boost Function 
                <div>
                </div>
            </div>
          </div>
          <div className="col-4">
            <FusionFieldDropdown onChange={handleFieldSelectionDropdownChange} />
          </div>
          <div className="col-4">
              <Slider 
                disabled={!(boostFunctionState.fieldName !== undefined && boostFunctionState.fieldName !== '')}
                size="small" 
                value={boostFunctionState.boost} 
                onChange={handleBoostChange}   
                valueLabelDisplay="auto"   
                step={.5}
                min={-10}
                max={10}/>
          </div>
          <div className="col-2 text-right">
            <div className="form-check form-switch">
            <span className="pull-right"><input className="form-check-input" type="checkbox" onChange={handleEnableDisable} checked={toggle} disabled={!(boostFunctionState.fieldName !== "")} /></span> 
            <div>
                </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  )
}
export default BoostFunction