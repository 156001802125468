import React, {  useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    issueNewContentSearch,
    issueNewSearch, selectContentType
} from './searchSlice';
import { useAppSelector } from '../../app/hooks';

function AutoSuggestSelection(props) {
    const dispatch = useDispatch();
    const contentTypeSelector = useAppSelector(selectContentType);
    useEffect(() => {
        if(props.query){
          if(contentTypeSelector === "content"){
            dispatch(issueNewContentSearch(props.query));
          } else{
            dispatch(issueNewSearch(props.query));
          }
        }
    }, [props.query, dispatch, contentTypeSelector]);
    return (
        <></>
    )
}

export default AutoSuggestSelection
