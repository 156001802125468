import React, { useState } from 'react';
import {
  setNavigationParam
} from './searchSlice';
import { useAppDispatch } from '../../app/hooks';
import { Param } from "./searchSlice";

export function SwitchOption(props:any) {
  const defaultValue = props.defaultValue;
  const paramName = props.paramName;

  const [toggle, setToggle] = useState(defaultValue);
  const dispatch = useAppDispatch();

  const handleInputChange = (event:any) => {
    const newValue = !toggle ;
    setToggle(newValue);
    const param: Param = { name: paramName, value: String(newValue) }
    dispatch(setNavigationParam(param))
  };


  return (
    <React.Fragment>
      <input className="form-check-input" type="checkbox" checked={toggle} onChange={handleInputChange}/>
    </React.Fragment>
  )
}
export default SwitchOption