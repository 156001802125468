import React from 'react';
import {
    issueNewSearch, issueNewContentSearch, selectBrowserSearchAppLocation, selectContentType
} from './searchSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import {
    useLocation
} from "react-router-dom";

export function ContentTypeSelectorTab(props: any) {
    const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
    const navigationParams = browserSearchAppLocation.queryParams;
    const dispatch = useDispatch();
    const contentTypeSelector = useAppSelector(selectContentType);
    const productClassName = (contentTypeSelector === "products") ? "nav-link active" : "nav-link"
    const contentClassName = (contentTypeSelector === "content") ? "nav-link active" : "nav-link"
    const location = useLocation();
    const pathname = location.pathname;
    const showContentSelectorTab = (pathname.startsWith("/search") || pathname.startsWith("/content")) ? true : false;


    const handleOnClick = (destination: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        console.log("handled click");

        if (destination === "product") {
            dispatch(issueNewSearch(navigationParams.q));
        }
        else if (destination === "content") {
            dispatch(issueNewContentSearch(navigationParams.q));
        }
    }

    return (
        <React.Fragment>
            {
                (showContentSelectorTab) ?
                    <ul className="nav nav-tabs contenttypeselector">
                        <li className="nav-item">
                            <a className={productClassName} aria-current="page" href="/" onClick={handleOnClick("product")}>Products</a>
                        </li>
                        <li className="nav-item">
                            <a className={contentClassName} href="/" onClick={handleOnClick("content")}>Content</a>
                        </li>
                    </ul>
                    : ""
            }
        </React.Fragment>
    )
}
export default ContentTypeSelectorTab