import React from "react";
import SwitchOption from "./SwitchOption";

function SynonymOutputList(props:any){
    const output = props.output;
    return(
        <React.Fragment>
        {
           output.map((out:string)=>
            <div className="rewrite-output-value">{out}</div>
           )
        }
        </React.Fragment>
    )
}

function SynonymOutput(props:any){
    const output = props.output;
    return(
        <React.Fragment>
        {
            (output != null && output.length > 0)
            ?<div className="rewrite-output">
                <SynonymOutputList output={output}/>
            </div>
            :""
        }
        </React.Fragment>
    )
}

function PhraseOutput(props:any){
    const phraseRule = props.phrase_rule;
    const surfaceForm = phraseRule.surfaceForm;
    const rewriteType = phraseRule.rewriteType;
    return(
        <React.Fragment>
        {(rewriteType === "phrase")
            ?
                <div className="rewrite-output">
                    <div className="rewrite-output-value">
                        "{surfaceForm}"
                    </div>
                </div>
            : ""
        }
            </React.Fragment>
    )
}

function RewritesList(props:any){
    const tagger = props.tagger;
    return(
        <React.Fragment>
        <div className="rules-rewrites-msg-wrapper">
        <div className="rewrites">
        {
            tagger.map((r:any) =>       
            <div className="rewrite">
                <div className={'rewrite-type-' + r.rewriteType}>{r.rewriteType}</div>
                <div className="rewrite-surface-form">{r.tag.surface_form}</div>                
                <div className="rewrite-action">{r.tag.action}</div>


                <SynonymOutput output={r.tag.output}/>
                <PhraseOutput phrase_rule={r}/>


            </div> 
            )
        }
        </div>
        </div>
        </React.Fragment>
    )
}
function Rewrites(props: any){
    const tagger = props.tagger;
    return (
        <React.Fragment>
        {
            (tagger.length === 0) 
            ? ""
            : <div className="col-md-6"><h3>Rewrites</h3><RewritesList tagger={tagger}/></div>
        }
        </React.Fragment>
    )
}

function RulesList(props:any){
    const rules = props.rules;
    return(
        <React.Fragment>

        <div className="rules-rewrites-msg-wrapper">            
        <div className="rewrites">{rules.length} rule(s) fired</div>
        <div className="rewrites">
        {
            rules.map((r:any) =>       
            <div className="rewrite">
                {r.name}
            </div> 
            )
        }
        </div>
        </div>
        </React.Fragment>
    )
}

function Rules(props: any){
    const rules = props.rules;
    return (
        <React.Fragment>
        {
            (rules.length === 0) 
            ?   <div className="rules-rewrites-empty">No relevant rules</div>
            : <div className="col-md-6"><h3>Rules</h3><RulesList rules={rules}/></div>
        }
        </React.Fragment>
    )
}

export function DemoControlPanel(props:any) {
    const solrResponse = props.solrResponse;
    const tagger_rewrites = (solrResponse && solrResponse.fusion && solrResponse.fusion.tagger) ? solrResponse.fusion.tagger : [];
    const triggrered_rules = (solrResponse && solrResponse.fusion && solrResponse.fusion.applicable_rules) ? solrResponse.fusion.applicable_rules : [];

    return (
        <div className="democontrolpanel">
            <div className="form-check form-switch">
                <SwitchOption defaultValue={true} paramName="enableSignalBoosts"/>
                <label className="form-check-label" >Boost with Signals</label>
                <div>
                </div>
            </div>


            <div className="form-check form-switch">
                <SwitchOption defaultValue={true} paramName="enableNeverNull"/>
                <label className="form-check-label" >Never Null</label>
                <div>
                </div>
            </div>

            <div className="form-check form-switch">
                <SwitchOption defaultValue={true} paramName="enableDynamicFacets"/>
                <label className="form-check-label" >Dynamic Facets</label>
                <div>
                </div>
            </div>

            <div className="rules-rewrites-wrapper">
                <div className="container">
                    <div className="row">
                        <Rules rules={triggrered_rules} />
                        <Rewrites tagger={tagger_rewrites}/>
                    </div>
                </div>
            </div>
        </div>

        
    )
}
export default DemoControlPanel