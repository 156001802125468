import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import BoostWithSignalsInsights from './BoostWithSignalsInsights'

function Badge(props: any) {
    const badge = props.badge;
    const solrResponseHeaderParams = props.solrResponseHeaderParams;
    return (
        <React.Fragment>
            {
                (badge.label === "Signal Boost")
                    ? <SignalBoostBadge badge={badge} solrResponseHeaderParams={solrResponseHeaderParams}/>
                    : <div className={"label_product " + badge.style}>
                        <img src={"/img/icon/" + badge.icon} className="rule_icon" alt="" /><span className="label_sale">{badge.label}</span>
                    </div>
            }
        </React.Fragment>
    )
}

function SignalBoostBadge(props: any) {
    const badge = props.badge;
    const [show, setShow] = useState(false);
    const handleShow = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        setShow(true)
    };
    const handleClose = () => setShow(false);
    const solrResponseHeaderParams = props.solrResponseHeaderParams;

    const query = solrResponseHeaderParams.q;
    const idTokens = badge.doc_id.split("/");
    const skuID = idTokens[idTokens.length - 1 ]

    return (
        <React.Fragment>
            <div className={"label_product " + badge.style}>
                <a href="/" onClick={handleShow}><img src={"/img/icon/" + badge.icon} className="rule_icon" alt="" /><span className="label_sale">{badge.label}</span></a>
            </div>
            <Modal show={show} onHide={handleClose} size="sm" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Aggregate Signal Insight for search "{query}" for Product {skuID} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BoostWithSignalsInsights closeModal={handleClose} query={solrResponseHeaderParams.q} doc_id={badge.doc_id}/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </React.Fragment>

    )
}

export function Badges(props: any) {
    const doc = props.doc;
    const badges: any = []
    const solrResponseHeaderParams = props.solrResponseHeaderParams;

    if (doc.pseudo_boosted) {
        badges.push({
            label: "Boosted",
            icon: "rule_boost.png",
            style: "rule_boost",
        });
    }

    if (doc.pseudo_pinned) {
        badges.push({
            label: "Pinned",
            icon: "rule_pinned.png",
            style: "rule_pinned"
        });
    }

    if (doc.pseudo_buried) {
        badges.push({
            label: "Buried",
            icon: "rule_buried.png",
            style: "rule_buried"
        });
    }

    if (doc.pseudo_signal_boosted) {
        badges.push({
            label: "Signal Boost",
            icon: "rule_signal_boost.png",
            style: "rule_signal_boosted",
            doc_id: doc.id_with_status
        });
    }

    return (
        <React.Fragment>
            {
                (badges.length > 0)
                    ? <Badge badge={badges[0]} solrResponseHeaderParams={solrResponseHeaderParams} />
                    : ""
            }
        </React.Fragment>
    )
}
export default Badges