import React, { useEffect } from 'react';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import {
  SearchAppLocation,
  replaceBrowserSearchAppLocation,
  replaceStateSearchAppLocation,
  selectBrowserSearchAppLocation,
  selectStateSearchAppLocation
} from './searchSlice';
import queryString from 'query-string'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { convertFiltersToURLStringForBrowser, convertPriceToURLStringForBrowser } from "./URLUtils"



export function URLSync() {
  const location = useLocation();
  const search = location.search;
  const pathname = location.pathname;
  const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
  const stateSearchAppLocation = useAppSelector(selectStateSearchAppLocation);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const filterPrefix = "f."

  function getCurrentFusionFiltersFromBrowserParams(browserQueryParams: any): any {
    var fusionFilters: any = {};
    const filterParamNames = Object.keys(browserQueryParams).filter((key: string) => { return key.startsWith(filterPrefix) })
    filterParamNames.forEach((filterParam: string) => {
      const val = browserQueryParams[filterParam];
      fusionFilters[filterParam.replace(filterPrefix, "")] = val;
    })
    return fusionFilters;
  }



  useEffect(() => {

    const paramsFromQueryString = queryString.parse(search)

    //make a clone of paramsFromQueryString
    const serializableParamsFromQueryString2 = JSON.parse(JSON.stringify(paramsFromQueryString));


    //filter out any parameters that start with filterPrefix
    const paramEntries = Object.entries(serializableParamsFromQueryString2).filter(
      ([key, value]) => {
        return !key.startsWith(filterPrefix) && !key.startsWith("price")
      }
    );
    const paramsWithoutFilter = Object.fromEntries(paramEntries);

    const filters = getCurrentFusionFiltersFromBrowserParams(serializableParamsFromQueryString2);
    const currentPrice = (serializableParamsFromQueryString2.price) ? serializableParamsFromQueryString2.price : ""


    const searchAppLocation: SearchAppLocation = {
      pathname: pathname,
      queryParams: paramsWithoutFilter,
      filters: filters,
      price: currentPrice,
    }

    dispatch(replaceBrowserSearchAppLocation(searchAppLocation));
    dispatch(replaceStateSearchAppLocation(searchAppLocation));
  }, [search, pathname, dispatch]);

  useEffect(() => {
    const browserSearchAppLocationAsString: string = JSON.stringify(browserSearchAppLocation);
    const stateSearchAppLocationAsString: string = JSON.stringify(stateSearchAppLocation);
    if (browserSearchAppLocationAsString !== stateSearchAppLocationAsString) {
      history.push({
        pathname: stateSearchAppLocation.pathname,
        search: queryString.stringify(stateSearchAppLocation.queryParams) + convertFiltersToURLStringForBrowser(stateSearchAppLocation.filters) + convertPriceToURLStringForBrowser(stateSearchAppLocation.price)
      })
    }
    else {
      //console.log("browser params and navigation params are equal.  No need to sync navigation params to browser");
    }
  }, [history, browserSearchAppLocation, stateSearchAppLocation]);


  return (
    <></>
  );
}
