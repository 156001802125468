import React from "react";
import SearchResults from "./SearchResults";
import ContentSearchResults from "./ContentSearchResults";
import ItemDetailZone from "./ItemDetailZone";
import { SimilarItemsZone } from "./SimilarItemsZone";
export function RenderSearchOrBrowsePage(props: any) {
    const data = props.data;
    const facetTypedZones = data.rendered.filter(function (item:any) {
        return item.zone.type === "facet";
    })

    const facetSolrResponse = (facetTypedZones != null && facetTypedZones.length > 0) ? facetTypedZones[0].render : null;

    return (
        <React.Fragment>
            {
                data.rendered.map((rendered: any) => {
                    switch (rendered.zone.name) {
                        case "Main Results List":
                            return <SearchResults key={rendered.zone.id} zone={rendered} facetSolrResponse={facetSolrResponse} />
                        case "Results List":
                            return <SearchResults key={rendered.zone.id} zone={rendered} facetSolrResponse={facetSolrResponse}/>
                        case "Content Results List":
                            return <ContentSearchResults key={rendered.zone.id} zone={rendered} />
                        case "Product Detail":
                            return <ItemDetailZone key={rendered.zone.id} zone={rendered} />
                        case "Similar Items":
                            return <SimilarItemsZone key={rendered.zone.id} zone={rendered} />
                        case "Trending Items":
                            return <SimilarItemsZone key={rendered.zone.id} zone={rendered} />
                        default:
                            return <React.Fragment key={rendered.zone.id}></React.Fragment>
                    }
                })
            }
        </React.Fragment>
    )
}
export default RenderSearchOrBrowsePage