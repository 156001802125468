import { BoostFunctionState, BoostQueryState, RelevanceRule } from "../custom_types";
import queryString from 'query-string'
import { paramsListToObject } from "../URLUtils";


export function relevanceRuleToQueryParam(rules: RelevanceRule[]):string {
    const activeRules = rules.filter((relevanceRule:RelevanceRule)=>{return relevanceRule.active})
        .map((current:RelevanceRule)=>{
             if(current.rule_type === "boost_function"){
                return  boostFunctionToQueryParam(current as BoostFunctionState)
            }
            else if(current.active && current.rule_type === "boost_query"){
                return boostQueryToQueryParam(current as BoostQueryState)
            }
            else{
                return "";
            }
        })

    if(activeRules.length > 0){
        return queryString.stringify(paramsListToObject(activeRules));
    }
    else{
        return "";
    }
}
  
function boostFunctionToQueryParam(boostFunction: BoostFunctionState):string[] {    
    /*
    scale(log(sku_revenue_f),0,<request.revenue_boost_max>).
    */
   if(boostFunction.boost > 0){
    return ["bf", "scale(log(" + boostFunction.fieldName + "),0," + boostFunction.boost + ")"];
   }
   else{
    var abs = boostFunction.boost * -1;
    return ["bf" , "scale(recip(log(" + boostFunction.fieldName + "),1,1000,1000),0," + abs + ")"];
   }
}

  
function boostQueryToQueryParam(boostQueryState: BoostQueryState):string[] {    
   return ["bq" ,  boostQueryState.fieldName + ':("' + boostQueryState.fieldValue + '")^' + boostQueryState.boost]; 
}