
import React from 'react';
import LeftFilters from "./LeftFilters";
import PaginationControls from "./PaginationControls";
import DropdownSearchOption from "./DropdownSearchOption"
import {
    selectBrowserSearchAppLocation
} from './searchSlice';
import { useAppSelector } from '../../app/hooks';
import ItemsPerPage from "./ItemsPerPage";
import {
    Link
} from "react-router-dom";
import ContentTypeSelectorTab from './ContentTypeSelectorTab';
import Breadcrumb from './Breadcrumb';
import H1 from './H1'
import Badges from './Badges'
import Rating from '@mui/material/Rating';
import { Box } from '@material-ui/core';
function ProductItem(props: any) {
    const doc = props.doc;
    const productCode = props.doc.product_id;
    const imageUrl = "https://newpig.scene7.com/is/image/NewPig/PRD_" + productCode + "_ISET?$PB02$";
    const solrResponseHeaderParams = props.solrResponseHeaderParams;
    const labelText = (value:number) =>{
        console.log("getting label text");
        return "hi";
    }

    return (
        <div className="col-md-4 col-sm-6 col-lg-4">

            <div className="single_product">
                <Badges doc={doc} solrResponseHeaderParams={solrResponseHeaderParams}/>
                <div className="product_name grid_name">
                    <h4 ><Link to={'/product/' + doc.product_id}>{doc.product_id} </Link></h4>
                    <h3><Link to={'/product/' + doc.product_id}>{doc.title}</Link></h3>
                   
                </div>
                <div className="product_thumb">
                    <Link className="primary_img" to={'/product/' + doc.product_id}><img src={imageUrl} alt="" /></Link>
                    <Link className="secondary_img" to={'/product/' + doc.product_id}><img src={imageUrl} alt="" /></Link>


                </div>
                <div className="product_content grid_content">
                    <div className="content_inner">
                        <div className="product_ratings">
                            <Rating
                                name="simple-controlled"
                                value={doc.rating}
                                size="medium"
                                getLabelText={labelText}
                             />  <span className="reviews">( {doc.reviews} ) </span>
                        </div>
                        <div className="product_footer d-flex align-items-center">
                            <div className="price_box">
                                {
                                    (doc.price_effective && doc.price_effective !== doc.price)
                                        ?
                                        <React.Fragment>
                                            <span className="current_price">$ {doc.price_effective.toFixed(2)}</span>
                                            <span className="old_price">{doc.price.toFixed(2)}</span>
                                        </React.Fragment>
                                        :
                                        <span className="current_price">$ {doc.price && doc.price.toFixed(2)}</span>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="product_content list_content">
                    <div className="left_caption">
                        <div className="product_name">
                            <h3><a href="product-details.html">Cas Meque Metus Shoes Core i7 3.4GHz, 16GB DDR3 {JSON.stringify(doc.id)}</a></h3>
                        </div>
                        <div className="product_ratings">
                            <ul>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                                <li><a href="/"><i className="ion-star"></i></a></li>
                            </ul>
                        </div>
                        <div className="product_desc">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis ad, iure incidunt. Ab consequatur temporibus non eveniet inventore doloremque necessitatibus sed, ducimus quisquam, ad asperiores </p>
                        </div>
                    </div>
                    <div className="right_caption">
                        <div className="text_available">
                            <p>availabe: <span>99 in stock</span></p>
                        </div>
                        <div className="price_box">
                            <span className="current_price">$160.00</span>
                            <span className="old_price">$420.00</span>
                        </div>
                        <div className="cart_links_btn">
                            <a href="/" title="add to cart">add to cart</a>
                        </div>
                        <div className="action_links_btn">
                            <ul>
                                <li className="quick_button"><a href="/" data-bs-toggle="modal" data-bs-target="#modal_box" title="quick view"> <span className="lnr lnr-magnifier"></span></a></li>
                                <li className="wishlist"><a href="wishlist.html" title="Add to Wishlist"><span className="lnr lnr-heart"></span></a></li>
                                <li className="compare"><a href="compare.html" title="compare"><span className="lnr lnr-sync"></span></a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

function ContentItem(props: any) {
    const doc = props.doc;
    return (
        <div className="col-12">

            <div className="single_product">
                <Badges doc={doc} />
                <div className="product_content list_content">
                    <div className="">
                        <div className="content_name">
                            <h3><a target="_blank" href={doc.url}>{doc.title}</a></h3>
                        </div>
                        <div className="product_desc">
                            <p><a target="_blank" href={doc.url}>{doc.url}</a></p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis ad, iure incidunt. Ab consequatur temporibus non eveniet inventore doloremque necessitatibus sed, ducimus quisquam, ad asperiores </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

function ResultItem(props: any) {
    const doc = props.doc;
    const document_type = (doc.document_type === "content") ? "content" : "product"
    const solrResponseHeaderParams = props.solrResponseHeaderParams;

    return (
        <React.Fragment>
            {(document_type === "content")
                ? <ContentItem doc={doc} />
                : <ProductItem doc={doc} solrResponseHeaderParams={solrResponseHeaderParams}/>
            }
        </React.Fragment>
    )
}




export function ShowingResults(props: any) {
    const solrResponse = props.solrResponse;
    const numFound = props.numFound;
    const parameters = solrResponse.responseHeader.params;
    const start = (parameters.start) ? (Number(parameters.start)) : 0;

    const rows: number = (parameters.rows) ? (Number(parameters.rows) + 0) : 10;
    const lastResult = start + rows;


    const showingEnd = (numFound < lastResult) ? numFound : lastResult;
    const showingStart: number = (numFound > 0) ? start + 1 : 0;

    return (
        <React.Fragment>
            <div className="page_amount">
                <p>Showing {showingStart}–{showingEnd} of {numFound} results</p>
            </div>
        </React.Fragment>
    )
}

export function SearchResults(props: any) {

    const solrResponse = props.zone.render;
    const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
    const navigationParams = browserSearchAppLocation.queryParams;
    const solrResponseHeaderParams = solrResponse.responseHeader.params;
    const facetSolrResponse = props.facetSolrResponse;
    const itemsPerPageOptions = [12, 24, 48];

    const sortOptions = [
        {
            "label": "Relevance",
            "value": ""
        },
        {
            "label": "Ratings",
            "value": "rating desc"
        },
        {
            "label": "Reviews",
            "value": "reviews desc"
        },
        {
            "label": "Price Low to High",
            "value": "$effective_price_formula asc"
        },
        {
            "label": "Price High to Low",
            "value": "$effective_price_formula desc"
        }
    ];

    const sortValue = navigationParams["sort"] != null ? navigationParams["sort"] : "";


    var docs = [];
    var numFound = 0;
    var groupId = "product_id";
    if (solrResponse && solrResponse.grouped && solrResponse.grouped[groupId]) {
        var groups: any = solrResponse.grouped[groupId].groups;
        groups.forEach((group: any) => {
            var representativeRecord = group.doclist.docs[0];
            docs.push(representativeRecord);
        })
        numFound = solrResponse.grouped[groupId].ngroups;
    }
    else if (solrResponse && solrResponse.response && solrResponse.response.docs) {
        docs = solrResponse.response.docs;
        numFound = solrResponse.response.numFound;
    }




    return (
        <React.Fragment>
            <Breadcrumb solrResponse={solrResponse} />
            {(numFound === 0)
                ?
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="shop_title">
                                <h1>We're sorry.  There were no results found for your search  <span className="searchTerm">{navigationParams.q}</span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="shop_title">
                                <H1 solrResponse={solrResponse} />
                                <ContentTypeSelectorTab solrResponse={solrResponse} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <LeftFilters solrResponse={facetSolrResponse} />
                        <div className="col-lg-9 col-md-12">

                            <div className="shop_toolbar_wrapper">
                                <ShowingResults solrResponse={solrResponse} numFound={numFound} />

                                <div className=" niceselect_option">
                                    <form className="select_option" action="#">
                                        <span className="sortByLabel">Sort By:</span>
                                        <DropdownSearchOption
                                            options={sortOptions}
                                            defaultValue={sortValue}
                                            paramName={"sort"}
                                            label="Sort By"
                                        />

                                    </form>


                                </div>

                            </div>

                            <div className="row shop_wrapper grid_3">
                                {
                                    docs.map((doc: any, index: number) => {
                                        return <ResultItem key={doc.id + index} doc={doc} solrResponseHeaderParams={solrResponseHeaderParams}/>
                                    })
                                }

                            </div>

                            <div className="shop_toolbar t_bottom">
                                <ItemsPerPage defaultValue={12} options={itemsPerPageOptions} />
                                <PaginationControls solrResponse={solrResponse} numFound={numFound} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}
export default SearchResults