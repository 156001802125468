import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import configData from "../../system_config.json"
import { getCurrentEnvironment } from "./EnvironmentConfiguration"
const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));

export const RulesAPI = createApi({
  reducerPath: 'rulesAPI',
  baseQuery: fetchBaseQuery(
    { baseUrl: configDataOfAnyType[getCurrentEnvironment()].fusion.url, 
      prepareHeaders: (headers, { getState }) => {
        var basicAuth = "Basic " + btoa(configDataOfAnyType[getCurrentEnvironment()].fusion.username + ":" + configDataOfAnyType[getCurrentEnvironment()].fusion.password);
        headers.set("Authorization", basicAuth);
        return headers
      },
    }  
  ),
  endpoints: (builder) => ({
    fieldsEndpoint: builder.query({
      query: (name) => {return '/api/solr/B2B/schema/fields'},
    })
  }),
})

export const { useFieldsEndpointQuery } = RulesAPI
