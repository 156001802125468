import React, { useState } from 'react';
import { useRenderEndpointQuery } from './FusionAPI'
import {
    selectBrowserSearchAppLocation, selectRelevanceRules
} from './searchSlice';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from './searchSlice';
import ReactJson from 'react-json-view'
import queryString from 'query-string'
import configData from "../../system_config.json"
import { convertFiltersToQueryString } from "./URLUtils"
import RenderHomePage from './RenderHomePage';
import RenderProductDetailPage from './RenderProductDetailPage';
import RenderSearchOrBrowsePage from './RenderSearchOrBrowserPage'
import { getCurrentEnvironment } from "./EnvironmentConfiguration"
import { Icon } from '@iconify/react';
import {relevanceRuleToQueryParam} from './predictive_merch/RelevanceRuleUtils'
import ProductDetailsCoPilotMoreInfo from './ProductDetailsCoPilotMoreInfo';

const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));

function buildUserFilter(user: any) {
    return "customer_id=" + user.customer_id ;
}


function Success(props: any) {
    const data = props.data;
    const templateTypes = props.templateTypes;
    var templateContainer = "Home";
    if (templateTypes.includes("item-detail")) {
        templateContainer = "ProductDetails"
    }
    else if (templateTypes.includes('home')) {
        templateContainer = "Home"
    }
    else {
        templateContainer = "SearchOrBrowse"
    }

    return(
        <React.Fragment>
        {(templateContainer === "Home" &&
            <RenderHomePage data={data}/>)
        || (templateContainer === "ProductDetails" &&
            <RenderProductDetailPage data={data}/>)
        || (templateContainer === "something")
        ||
            <RenderSearchOrBrowsePage data={data}/>
        }
        </React.Fragment>
    )
}
/*
function Loading() {
    return (
        <React.Fragment>Loading</React.Fragment>
    )
}
*/
function Error(props: any) {
    return (
        <React.Fragment>
            <div className="alert alert-danger" role="alert">
                An error has occurred.
                <p>{JSON.stringify(props.error)}</p>
            </div>
            <hr />
            <div>
            </div>
        </React.Fragment>
    )
}




//builds the URL of the Fusion Endpoint
const fusionQueryEndpoint = (fusionParam: any) => {
    //console.log("Querying.  fusionParam:" + JSON.stringify(fusionParam))
    const queryParamsFromBrowser = fusionParam.searchAppLocation.queryParams;
    const price = fusionParam.searchAppLocation.price;
    const user = fusionParam.user;
    const additionalParams: any = fusionParam.additionalParams;
    const params: any = { ...queryParamsFromBrowser, ...additionalParams };

    if (Object.keys(params).length === 0) {
        params.q = "*:*";
    }

    //adds template types
    params['templateTypes'] = fusionParam.templateTypes.join(",");


    const priceFilter =  (price  && price !== "") ? "&fq=" + encodeURIComponent(price)  : "" ;
    console.log(" fusionParam.searchAppLocation=" +  JSON.stringify(fusionParam.searchAppLocation))

    //build user related parameter
    const userFilter = (user === null || Object.keys(user).length === 0) ? "" : buildUserFilter(user);

    const ret = "/templating/render/" 
    + configDataOfAnyType[getCurrentEnvironment()].fusion.app 
    + "?json.nl=arrarr&" + queryString.stringify(params) 
    + convertFiltersToQueryString(fusionParam.searchAppLocation.filters) 
    + userFilter + priceFilter 
    + relevanceRuleToQueryParam(fusionParam.relevanceRules);

    return ret;
}

const FusionRender = (props: any) => {
    const browserSearchAppLocation: any = useAppSelector(selectBrowserSearchAppLocation);
    const user: any = useAppSelector(selectUser);
    const templateTypes = props.templateTypes;
    const additionalParams = props.additionalParams;
    const [showMoreInfoPanel, setShowMoreInfoPanel] = useState(false);
    const relevanceRules = useAppSelector(selectRelevanceRules)

    var queryParm = {
        searchAppLocation: browserSearchAppLocation,
        user: user,
        templateTypes: templateTypes,
        additionalParams: additionalParams,
        relevanceRules: relevanceRules
    }

    const pathAndQueryString = fusionQueryEndpoint(queryParm);
    const fusionRenderURL = configDataOfAnyType[getCurrentEnvironment()].fusion.url + pathAndQueryString;

    const userRenderParam = {
        pathAndQueryString: pathAndQueryString
    }
    const { data, error } = useRenderEndpointQuery(userRenderParam)

    const onChangeHandler = (e: any) => {
        e.preventDefault();
    }

    return (
        <React.Fragment>
            {error ? (
                <React.Fragment>
                    <Error error={error} />
                </React.Fragment>
            ) : data ? (
                <>
                    <React.Fragment>
                        <Success data={data} templateTypes={templateTypes} />

                    </React.Fragment>
                    <div className="container py-3">
                        {(showMoreInfoPanel)
                        ?
                            <>
                            <a href="/" onClick={(e) => {e.preventDefault(); setShowMoreInfoPanel(!showMoreInfoPanel) }}><Icon icon="material-symbols:keyboard-arrow-down" width="25" /></a>                        
                            <hr />
                            <div className="fusionRender_debug_panel">
                                <h4><a href={fusionRenderURL} target="_new">URL (link)</a></h4>
                                <textarea className="form-control" value={fusionRenderURL} onChange={onChangeHandler} />
                                <div>
                                    <h4>URL Decoded</h4>
                                    <textarea className="form-control" value={decodeURIComponent(fusionRenderURL)} onChange={onChangeHandler} />

                                </div>
                                <ReactJson src={data} collapsed={true} />
                            </div>
                            <ProductDetailsCoPilotMoreInfo/>
                            </>                        
                        :<><a href="/" onClick={(e) => {e.preventDefault(); setShowMoreInfoPanel(!showMoreInfoPanel) }}><Icon icon="material-symbols:keyboard-arrow-right" width="25" /></a>                        </>
                        }
                    </div>

                </>
            ) : null}
        </React.Fragment>
    )
}

export default FusionRender