import Table from 'react-bootstrap/Table'
import { useUsersEndpointQuery, useCustomersEndpointQuery } from './FusionAPI'
import React from 'react';
import {
  setUser
} from './searchSlice';
import { useAppDispatch } from '../../app/hooks';
import TextField from '@mui/material/TextField';



function UserRow(props: any) {

  const user = props.user;
  const dispatch = useAppDispatch();

  const handleOnClick = (user: any) => (e: any) => {
    e.preventDefault();
    dispatch(setUser(user));
    props.closeModal();
  }

  const discount = (user.customer_price_discount != null) ? user.customer_price_discount * 100 : 0;

  return (
    <tr>
      <td>{user.customer_id}</td>
      <td>{user.customer_name}</td>
      <td>{discount}</td>
      <th><a href="/" onClick={handleOnClick(user)}>Select</a></th>
    </tr>
  )
}

function Error(props: any) {
  return (
    <React.Fragment>
      <div className="alert alert-danger" role="alert">
        An error has occurred.
        <p>{JSON.stringify(props.error)}</p>
      </div>
      <hr />
      <div>
      </div>
    </React.Fragment>
  )
}

function augmentUsers(usersFromFusion: any) {

  const emailToUserIDMap:any = {
    "bob.miller@distributiongroup.com": "b358c4bc1d4a692ea417ce7d5a90b785@test.com",
    "dane.smith@acmehealth.org": "cf28c1b7d9a9bd14c0f5b8da6aafd367@test.com"
  }

  const augmentedUsers = usersFromFusion.map((user:any) => {
      //clone the user object
      var augmentedUser = JSON.parse(JSON.stringify(user));
      const userIdLookedUp:string = emailToUserIDMap[augmentedUser.account_email];
      console.log("user id looked up is: " + userIdLookedUp);
      augmentedUser.user_id = userIdLookedUp;
      return augmentedUser;
    }
  )
  return augmentedUsers;
}

export function UsersList(props: any) {
  const data = props.data;
  const usersFromFusion = (data != null) ? data.response.docs : [];
  const dispatch = useAppDispatch();

  const augmentedUsers = augmentUsers(usersFromFusion);

  const handleLogoutOnClick = (user: any) => (e: any) => {
    e.preventDefault();
    dispatch(setUser(user));
    props.closeModal();
  }
  return (
    <React.Fragment>
      <Table striped bordered hover variant="thread-light">
        <thead className="thread-dark">
          <tr>
            <th>Customer ID</th>
            <th>Customer Name</th>
            <th>Discount (%)</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {
            augmentedUsers.map((user: any) => {
              return <UserRow user={user} closeModal={props.closeModal} />
            })
          }

        </tbody>
      </Table>

      <div className="logout">
        <button type="button" className="btn btn-primary" onClick={handleLogoutOnClick({})}>Logout / Guest</button>
      </div>

    </React.Fragment>
  )
}

export function Users(props: any) {
  const [query, setQuery] = React.useState("");

  const handleTextFieldChange = (event: any) => {
    if (event.key === "Escape") {
        ;
    }
    else {
      setQuery(event.target.value);
    }
  };


  return (
    <React.Fragment>
      <div className="content-wrapper oh">
        <section className="section-wrap intro pb-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mb-50">
              <TextField id="outlined-search" label="Search Customer" type="search" size='small' onChange={handleTextFieldChange}/>

              <hr className="mb-0" />

              <Users2 query={query} closeModal={props.closeModal} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}
export default Users



export function ShowingResults(props: any) {
  const solrResponse = props.solrResponse;
  const numFound = props.numFound;
  const parameters = solrResponse.responseHeader.params;
  const start = (parameters.start) ? (Number(parameters.start)) : 0;

  const rows: number = (parameters.rows) ? (Number(parameters.rows) + 0) : 10;
  const lastResult = start + rows;


  const showingEnd = (numFound < lastResult) ? numFound : lastResult;
  const showingStart: number = (numFound > 0) ? start + 1 : 0;

  return (
      <React.Fragment>
          <div className="page_amount">
              <p>Showing {showingStart}–{showingEnd} of {numFound} results</p>
          </div>
      </React.Fragment>
  )
}

export function Users2(props: any) {
  const query = props.query;
  const q = (props.query !== null && props.query !== "" ) ? props.query : "*:*"
  const { data, error } = useCustomersEndpointQuery({"q": q})
  return (
    <React.Fragment>
      <div className="content-wrapper oh">
        <section className="section-wrap intro pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 mb-50 ">
                {error ? (
                  <React.Fragment>
                    <Error error={error} />
                  </React.Fragment>
                ) : data ? (
                  <>
                    <React.Fragment>
                      <div className="py-3">
                        <ShowingResults solrResponse={data} numFound={data.response.numFound} />
                      </div>

                      <UsersList data={data} closeModal={props.closeModal} />
                    </React.Fragment>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}