import React from "react";
import FusionRender from "./FusionRender";
import {
    useLocation
  } from "react-router-dom";

export function Browse(props: any) {
    const location = useLocation();
    const additionalParams = {
        cat: location.pathname,
        q: "*:*"
    }
    return (
        <React.Fragment>
            <FusionRender templateTypes={["browse"]} additionalParams={additionalParams}/>
        </React.Fragment>
    )
}
export default Browse