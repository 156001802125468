import { useBoostWithSignalsInsightsByQueryEndpointQuery } from './FusionAPI'
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table'
import SwitchOptionNoRedux from './SwitchOptionNoRedux';

function SignalRow(props: any) {

  const Signal = props.Signal;
  const selectedDocId = props.selectedDocId;
  const filterByProduct = props.filterByProduct;

  const idTokens = Signal.doc_id_s.split("/");
  const skuID = idTokens[idTokens.length - 1 ]
  const isSelectedStyle = (!filterByProduct && Signal.doc_id_s === selectedDocId) ? "signal_selected" : "signal";

  return (
    <tr className={isSelectedStyle}>
      <td>{Signal.query_s}</td>
      <td>{skuID}</td>
      <td>{Signal.aggr_count_i}</td>
      <td>{Signal.weight_d}</td>
    </tr>
  )
}

function Error(props: any) {
  return (
    <React.Fragment>
      <div className="alert alert-danger" role="alert">
        An error has occurred.
        <p>{JSON.stringify(props.error)}</p>
      </div>
      <hr />
      <div>
      </div>
    </React.Fragment>
  )
}


export function SignalsList(props: any) {
  const data = props.data;
  const SignalsFromFusion = (data != null) ? data.response.docs : [];
  const selectedDocId = props.selectedDocId;

  const handleExit = (Signal: any) => (e: any) => {
    e.preventDefault();
    props.closeModal();
  }

  const [filterByProduct, setFilterByProduct] = useState(true);
  const filteredSignalsFromFusion = SignalsFromFusion.filter(function (signal:any) {
    return signal.doc_id_s === selectedDocId;
  })

  const signals = (filterByProduct) ? filteredSignalsFromFusion : SignalsFromFusion;

  return (
    <React.Fragment>
      <div className="form-check form-switch">
        Filter by SKU ID <SwitchOptionNoRedux toggle={filterByProduct} setToggle={setFilterByProduct}/>
      </div>
      <Table striped bordered hover variant="thread-light">
        <thead className="thread-dark">
          <tr>
            <th>Query</th>
            <th>SKU ID</th>
            <th>Aggr Count</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          {
            signals.map((Signal: any) => {
              return <SignalRow Signal={Signal} closeModal={props.closeModal} selectedDocId={selectedDocId} filterByProduct={filterByProduct}/>
            })
          }

        </tbody>
      </Table>
      <div className="logout">
        <button type="button" className="btn btn-primary" onClick={handleExit({})}>Close</button>
      </div>
    </React.Fragment>
  )
}


export function BoostWithSignalsInsights(props: any) {
  const doc_id = props.doc_id;
  const query = props.query;

  const { data, error } = useBoostWithSignalsInsightsByQueryEndpointQuery({query: query})

  return (
    <React.Fragment>
      <div className="content-wrapper oh">
        <section className="section-wrap intro pb-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mb-50">
                {error ? (
                  <React.Fragment>
                    <Error error={error} />
                  </React.Fragment>
                ) : data ? (
                  <>
                    <React.Fragment>
                      <SignalsList data={data} closeModal={props.closeModal} selectedDocId={doc_id} />
                    </React.Fragment>
                  </>
                ) : null}
              </div>
            </div>
            <hr className="mb-0" />
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}
export default BoostWithSignalsInsights