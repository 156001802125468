import React from 'react';
import {
  useLocation
} from "react-router-dom";
import { useAppSelector } from '../../app/hooks';
import {
  issueNewSearch, selectBrowserSearchAppLocation
} from './searchSlice';
import { useDispatch } from 'react-redux';

export function H1(props: any) {

  const solrResponse = props.solrResponse;
  const location = useLocation();
  const pathname = location.pathname;
  const pageType = (pathname.startsWith("/search") || pathname.startsWith("/content")) ? "search" : "browse";

  return (
    <React.Fragment>
      {(pageType === "browse")
        ? <BrowseH1 solrResponse={solrResponse} />
        : <SearchH1Controller solrResponse={solrResponse}/>
      }
    </React.Fragment>
  )
}
export default H1

function SearchH1VectorSearch(props:any){
  const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
  const navigationParams = browserSearchAppLocation.queryParams;
  return(
    <h1>We were not able to find a match for <span className="searchTerm">{navigationParams.q}</span>. Try another search or shop related products.</h1>
  )

}


function SuggetionsList(props:any){
  const spellSuggesitons:string[] = props.spellSuggesitons;
  const correctedTerm = props.correctedTerm;
  const spellSuggestionsWithoutCorrected = spellSuggesitons.filter((s:string) => s !== correctedTerm)
  const dispatch = useDispatch();
  const handleOnclick = (searchTerm:string) => (e:any) => {
    e.preventDefault();
    dispatch(issueNewSearch(searchTerm));
  }

  return(
    <React.Fragment>
      {
        spellSuggestionsWithoutCorrected.map((suggestion:any, index:number) => {
          const last = (index === spellSuggestionsWithoutCorrected.length -1 ) ? true : false
          return (
            <span><a href="/" onClick={handleOnclick(suggestion)}>{suggestion}</a>
            {
              (!last)
              ? <React.Fragment>, </React.Fragment>
              : null
            }
            </span>
          )
        }
        )
      }
    </React.Fragment>
  )
}

function SpellingCorrectionSuggestionsController(props:any){
  const spellSuggesitons = props.spellSuggesitons;
  const hasSpellSuggestions = (spellSuggesitons != null && spellSuggesitons.length > 1);
  const correctedTerm = props.correctedTerm;

  return(
    <React.Fragment>
      {
      (hasSpellSuggestions)
      ? <p className="didyoumean"><strong>Did You Mean: </strong> 
      <SuggetionsList spellSuggesitons={spellSuggesitons} correctedTerm={correctedTerm}/></p>
      : null
      }
    </React.Fragment>
  )
}

function SearchH1SpellCorrection(props:any){
  const solrResponse = props.solrResponse;
  const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
  const navigationParams = browserSearchAppLocation.queryParams;
  const correctedTerm = solrResponse.b2b.spell_correctedTerm;
  const spellSuggesitons = solrResponse.b2b.spell_suggestions;

  return(
    <React.Fragment>
    <h1>We were not able to find a match for <span className="searchTerm">{navigationParams.q}</span>.  Here are results for <span className="searchTerm">{correctedTerm}</span> instead</h1>
    <SpellingCorrectionSuggestionsController spellSuggesitons={spellSuggesitons} correctedTerm={correctedTerm}/>
    </React.Fragment>
  )
}

function SearchH1(props:any){
  const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
  const navigationParams = browserSearchAppLocation.queryParams;
  return(
    <h1>Search Results for <span className="searchTerm">{navigationParams.q}</span></h1>
  )
}


function SearchH1Controller(props: any) {
  const solrResponse = props.solrResponse;
  const isVectorSearch = (solrResponse.B2B && solrResponse.B2B.vector_search === "true") ? true : false;
  const isSpellingCorrection = (solrResponse.b2b && solrResponse.b2b.spell_correction 
    && solrResponse.b2b.spell_correction === true) ? true : false;


  if (isVectorSearch) {
    return <SearchH1VectorSearch/>;
  }
  else{
    if(isSpellingCorrection){
      return <SearchH1SpellCorrection solrResponse={solrResponse}/>
    }
    else{
      return <SearchH1/>
    }
  } 
}


function BrowseH1(props: any) {
  const solrResponse = props.solrResponse;
  const breadcrumbResponse = (solrResponse.b2b && solrResponse.b2b.breadcrumb) ? solrResponse.b2b.breadcrumb : []
  return (
      <h1>{breadcrumbResponse[breadcrumbResponse.length - 1 ].name}</h1>
  )
}
