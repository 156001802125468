import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import searchReducer from '../features/search/searchSlice';
import { FusionEndpoint } from '../features/search/FusionAPI';
import { RulesAPI } from '../features/search/RulesAPI';
import {localStorageMiddleware, LOCAL_STORAGE_NAME} from './LocalStorageMiddleware.js';
import { initialState } from '../features/search/searchSlice';


//read redux state.search.persisted from local storage and merge with initial state
const reduxState:any = localStorage.getItem(LOCAL_STORAGE_NAME);
const persistedSearchState = (reduxState != null) ? JSON.parse(reduxState) : initialState.persisted;
const combinedState = {...initialState};
combinedState.persisted = persistedSearchState;
const persistedState = {
  search: combinedState
}



export const store = configureStore({
  reducer: {
    search: searchReducer,
    [FusionEndpoint.reducerPath]: FusionEndpoint.reducer,
    [RulesAPI.reducerPath]: RulesAPI.reducer
  },
  preloadedState: persistedState,
    // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(FusionEndpoint.middleware)
    .concat(RulesAPI.middleware)
    .concat(localStorageMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
