import React, { useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import {
    setNavigationParam
} from './searchSlice';
import { Param } from "./searchSlice";

export function ItemsPerPage(props: any) {
    const defaultValue: number = props.defaultValue;
    const options: Array<number> = props.options;
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const dispatch = useAppDispatch();
    
    const handleOnClick = (count: number) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setSelectedValue(count);
        const param: Param = { name: "rows", value: String(count) }
        dispatch(setNavigationParam(param))
    }

    return (
        <React.Fragment>
            <div className="itemsPerPage"><span>Show</span>
                <ul className="itemsPerPageList">
                    {
                        options.map((option: number, index:number) => {
                            var className = "";

                            if(option === selectedValue){
                                className = "active";
                            }
                            return  <li key={'itemsperpage-' + option}><a className={className} href="/" onClick={handleOnClick(option)}>{option}</a></li>
                        }
                        )
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}
export default ItemsPerPage