import React from 'react';

import { BoostFunctionState, RelevanceRule } from "../custom_types";
import { Icon } from '@iconify/react';
import {
  toggleFrontEndRelevance, selectRelevanceRules, addRelevanceRule
} from '../searchSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import BoostFunction from './BoostFunction';
import BoostQuery from './BoostQuery';
import { BoostQueryState } from '../custom_types';

import { v4 as uuidv4 } from 'uuid';

export function Relevance() {
  const dispatch = useAppDispatch();
  const relevanceRules = useAppSelector(selectRelevanceRules);


  const handleHideRelevanceClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(toggleFrontEndRelevance())
  }

  const handleAddClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const newRule:BoostFunctionState = {
      id: uuidv4(),
      active: false,
      rule_type: "boost_function",
      fieldName: "",
      boost: 0
    }
    dispatch(addRelevanceRule(newRule))
  }

  const handleAddClickBoostByValue = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const newRule:BoostQueryState = {
      id: uuidv4(),
      active: false,
      rule_type: "boost_query",
      fieldName: "",
      fieldValue: "",
      boost: 0
    }
    dispatch(addRelevanceRule(newRule))
  }

  return (
    <React.Fragment>
      <div className="relevance container py-4">
        <div className="row py-2">
          <div className="col-12">
            <div className="shop_title">
              <h3>
                <a href="/" onClick={handleHideRelevanceClick}><Icon icon="ic:round-expand-less" width="25" /></a>
                Relevance Rules</h3>

                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><a href="/" onClick={handleAddClick}><Icon icon="material-symbols:add" width="25" />Boost by Numeric Field</a></li>
                  <li className="list-group-item"><a href="/" onClick={handleAddClickBoostByValue}><Icon icon="material-symbols:add" width="25" />Boost by Field Value</a></li>
                </ul>
            </div>
          </div>
        </div>
        {
          relevanceRules.map((rule: RelevanceRule, index: number) => {
            switch (rule.rule_type) {
              case 'boost_function':
                return <BoostFunction rule={rule} />
              case 'boost_query':
                return <BoostQuery rule={rule} />
              default:
                return null
            }
          })
        }

      </div>
    </React.Fragment>
  )
}
export default Relevance