import axios, { AxiosRequestConfig } from "axios";
import { GroupBase, OptionsOrGroups } from "react-select";
import AsyncSelect from 'react-select/async';
import configData from "../../../system_config.json";
import { getCurrentEnvironment } from "../EnvironmentConfiguration";

const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));

/*
https://efdemos.lucidworkssales.com/api/solr/B2B/terms?terms.fl=product_id&terms.regex=.*a.*&terms.regex.flag=case_insensitive&terms.limit=100&omitHeader=true&distrib=true
https://efdemos.lucidworkssales.com/api/solr/B2B/terms?terms.fl=product_id&terms.regex.flag=case_insensitive&terms.limit=100&omitHeader=true&distrib=true&terms.regex=*D.**
                                                                                                                                                         &terms.regex=.*a.*
https://efdemos.lucidworkssales.com/api/solr/B2B/terms?terms.fl=product_id&terms.regex.flag=case_insensitive&terms.limit=100&omitHeader=true&distrib=true&terms.regex=.*D.**
*/


  export function FusionFieldValueDropdown<Option, Group extends GroupBase<Option> = GroupBase<Option>>(props: any) {


  const fetchFields = (inputValue: string, callback: (options: OptionsOrGroups<Option, Group>) => void) => {
    var params = new URLSearchParams();    
    params.append("terms.regex", '.*' + inputValue + '.*');


    const urlStr = configDataOfAnyType[getCurrentEnvironment()].fusion.url + '/api/solr/B2B/terms?terms.fl=' + props.fieldName + '&terms.regex.flag=case_insensitive&terms.limit=100&omitHeader=true&distrib=true&json.nl=arrarr'
    const options: AxiosRequestConfig = {
      url: urlStr,
      method: "get",
      params: params,
      auth: {
        username: 'admin',
        password: 'm2cool2b2',
      },
    };

    axios(options)
      .then((res) => res.data)
      .then((result: any) => {
        console.log("result found " + JSON.stringify(result))
        const options: Option[] = [];

        result.terms[props.fieldName].forEach((item: any) => {
          var anOption: any = { label: item[0], value: item[0] }
          options.push(anOption);
        })

        callback(options);
      })
      .catch((err) => {
        console.error("Error occurred in querying Fusion: " + err.message);
      });
  }


  return (
    <>
    <AsyncSelect 
      {... props}
      loadOptions={fetchFields}/>
    </>
  )
}
export default FusionFieldValueDropdown