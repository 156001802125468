import { debounce } from 'lodash';

export const LOCAL_STORAGE_NAME = 'b2bReduxState';

const debouncedLocalStorageUpdate = debounce((state) => {
  if(state.search.persisted != null){
    localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(state.search.persisted));
  }
}, 1000); // Adjust the debounce interval as needed

export const localStorageMiddleware = store => next => action => {
  const result = next(action);
  debouncedLocalStorageUpdate(store.getState());
  return result;
};