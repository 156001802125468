import React from 'react';
import { useDispatch } from 'react-redux';
import {
    setSort
} from './searchSlice';

export function DropdownSearchOption(props: any) {
  const dispatch = useDispatch();
  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const sortKey:string = e.target.value;
    dispatch(setSort(sortKey));
  }


    return (
        <React.Fragment>
            <div>
                <select  onChange={handleInputChange} name="orderby" id="short" value={props.defaultValue}>
                    {props.options.map((opt: any) =>
                        <option key={opt.value} value={opt.value}  >{opt.label}</option>
                    )
                    }

                </select>
            </div>

        </React.Fragment>
    )
}
export default DropdownSearchOption