import React from "react";
import SearchResults from "./SearchResults";
import ContentSearchResults from "./ContentSearchResults";
import ItemDetailZone from "./ItemDetailZone";
import { SimilarItemsZone } from "./SimilarItemsZone";


export function RenderHomePage(props:any) {
  return (
    <React.Fragment>
        {
          props.data.rendered.map((rendered: any) => {
              switch (rendered.zone.name) {
                  case "Main Results List":
                      return <SearchResults key={rendered.zone.id} zone={rendered}/>
                  case "Results List":
                      return <SearchResults key={rendered.zone.id} zone={rendered}/>
                  case "Content Results List":
                      return <ContentSearchResults key={rendered.zone.id} zone={rendered}/>         
                  case "Product Detail":
                          return <ItemDetailZone key={rendered.zone.id} zone={rendered}/>
                  case "Similar Items":
                      return <SimilarItemsZone key={rendered.zone.id} zone={rendered}/>
                  case "Trending Items":
                      return <SimilarItemsZone key={rendered.zone.id} zone={rendered}/>
                  default:
                      return <React.Fragment key={rendered.zone.id}/>
              }
          })
      }
    </React.Fragment>
  )
}
export default RenderHomePage
