import React from "react";
import FusionRender from "./FusionRender";
import {
    useLocation
  } from "react-router-dom";

export function Search(props: any) {
    const location = useLocation();
    const pathName = location.pathname;
    var additionalParams:any = {}
    if(pathName !== "/search"){
        additionalParams.cat = pathName.replace("/search","");
    }

    return (
        <React.Fragment>
            <FusionRender templateTypes={["landing"]} additionalParams={additionalParams}/>
        </React.Fragment>
    )
}
export default Search