import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchCount } from './SearchAPI';
import { RelevanceRule, BoostFunctionState} from './custom_types';
export interface SearchState {
  value: number;
  browserSearchAppLocation: any
  stateSearchAppLocation: any
  status: 'idle' | 'loading' | 'failed';
  user: {},
  frontend: any,
  contentType: string
  relevance_rules: RelevanceRule[],
  pdp_copilot: any
  persisted: {
    cart: CartItem[]
  }
}

export interface SearchAppLocation {
  pathname: string,
  queryParams: any
  filters: any,
  price: string
}

export interface Param {
  name: string,
  value: any
}

export interface RelevanceRuleChangeParam{
  id: string,
  ruleState: RelevanceRule | BoostFunctionState
}

export interface CartItem {
  id: string,
  title: string,
  thumbnail: string,
  quantity: number,
  price: number
}

export interface SetCartItemQuantityParam {
  id: string,
  quantity: number
}

export const initialState: SearchState = {
  value: 0,
  status: 'idle',
  user: {},
  browserSearchAppLocation: {},
  stateSearchAppLocation: {},
  frontend: {relevance: false},
  contentType: "products",
  relevance_rules: [],
  pdp_copilot: {},
  persisted: {
    cart: []
  }
};



// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const searchSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
      state.stateSearchAppLocation.queryParams['q'] = state.value;

    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    replaceBrowserSearchAppLocation: (state, action: PayloadAction<Object>) => {
      state.browserSearchAppLocation = action.payload;
    },
    replaceStateSearchAppLocation: (state, action: PayloadAction<Object>) => {
      state.stateSearchAppLocation = action.payload;
    },
    setUser: (state, action: PayloadAction<Object>) => {
      state.user = action.payload;
    },
    setPDPCoPilot: (state, action: PayloadAction<Object>) => {
      state.pdp_copilot = action.payload;
    },
    toggleFrontEndRelevance: (state) => {
      const currentState = state.frontend.relevance;
      state.frontend.relevance = !currentState;
    },
    modifyRelevanceRule:(state,action:PayloadAction<RelevanceRuleChangeParam>) =>{
      const id = action.payload.id;
      const newState = action.payload.ruleState;
      var ruleIndexFound:any = undefined;

      //search for the rule we're looking to update by comparing ID's
      for(var index in state.relevance_rules){
        if(state.relevance_rules[index].id === id){
          ruleIndexFound = index;
        }
      }

      //If we found the item we're looking for, perform the update
      if(ruleIndexFound){
        state.relevance_rules[ruleIndexFound] = newState
      }
    } ,
    deleteRelevancyRule:(state,action:PayloadAction<RelevanceRuleChangeParam>) =>{
      const id = action.payload.id;
      var ruleIndexFound:any = undefined;

      //search for the rule we're looking to update by comparing ID's
      for(var index in state.relevance_rules){
        if(state.relevance_rules[index].id === id){
          ruleIndexFound = index;
        }
      }

      //If we found the item we're looking for, perform the update
      if(ruleIndexFound){
        state.relevance_rules.splice(ruleIndexFound, 1);
      }
    } ,
    addRelevanceRule:(state,action:PayloadAction<RelevanceRule>) =>{
       state.relevance_rules.push(action.payload)
    } ,
    issueNewSearch: (state, action: PayloadAction<string>) => {
      const query = action.payload;
      
      console.log("inside issue new search");

      state.stateSearchAppLocation.pathname = "/search"
      state.stateSearchAppLocation.queryParams.q = query;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['sort'] ;
      delete state.stateSearchAppLocation.price;
      state.stateSearchAppLocation.filters = {};
      state.contentType = "products";
    },
    issueNewContentSearch: (state, action: PayloadAction<string>) => {
      const query = action.payload;
      console.log("setting query params.q to " + query);

      state.stateSearchAppLocation.pathname = "/content"
      state.stateSearchAppLocation.queryParams.q = query;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['sort'] ;
      state.stateSearchAppLocation.filters = {};
      state.contentType = "content";

    },
    setNavigationParam: (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.queryParams[param.name] = param.value;
    },
    setPrice:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      console.log("in slice set price " + param.value)
      state.stateSearchAppLocation.price = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
    },
    setSort: (state, action: PayloadAction<string>) => {
      const sortKey:string = action.payload;
      if(sortKey === null || sortKey === ""){
        delete state.stateSearchAppLocation.queryParams['sort'];
      }
      else{
        state.stateSearchAppLocation.queryParams['sort'] = sortKey;
      }
      delete state.stateSearchAppLocation.queryParams['start'];
    },
    setFilter:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.filters[param.name] = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
    },
    browseCategoryPath:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.pathname = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['q'] ;
    },
    browseMenuPath:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.pathname = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
      delete  state.stateSearchAppLocation.queryParams['q'] ;
      state.stateSearchAppLocation.filters = {};
    },
    searchCategoryPath:  (state, action: PayloadAction<Param>) => {
      const param:Param = action.payload;
      state.stateSearchAppLocation.pathname = param.value;
      delete  state.stateSearchAppLocation.queryParams['start'] ;
    },
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const cartItem:CartItem = action.payload;
      const existingItem = state.persisted.cart.find((item) => item.id === cartItem.id);
      if(existingItem){
        existingItem.quantity += cartItem.quantity;
      }
      else{
        state.persisted.cart.push(action.payload);
      }
    },
    setCartItemQuantity: (state, action: PayloadAction<SetCartItemQuantityParam>) => {
      const productId = action.payload.id;
      const quantity = action.payload.quantity;
      const existingItem = state.persisted.cart.find((item) => item.id === productId);
      if(existingItem){
        existingItem.quantity = quantity;
      }
    },
    emptyCart: (state) => {
      state.persisted.cart = [];
    },
    removeFromCart: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      const existingItem = state.persisted.cart.find((item) => item.id === productId);
      if(existingItem){
        state.persisted.cart = state.persisted.cart.filter((item) => item.id !== productId);
      }
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      });
  },
});

export const { increment, decrement, incrementByAmount, setUser, 
  issueNewSearch, setNavigationParam, setSort, 
  replaceBrowserSearchAppLocation,
  replaceStateSearchAppLocation,
  setFilter, browseCategoryPath, searchCategoryPath, browseMenuPath,
  toggleFrontEndRelevance, issueNewContentSearch, modifyRelevanceRule, 
  addRelevanceRule, deleteRelevancyRule, setPDPCoPilot, setPrice, addToCart,
  emptyCart,removeFromCart, setCartItemQuantity
  } = searchSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.search.value;

export const selectBrowserSearchAppLocation = (state: RootState) => state.search.browserSearchAppLocation;
export const selectStateSearchAppLocation = (state: RootState) => state.search.stateSearchAppLocation;
export const selectUser = (state: RootState) => state.search.user;
export const selectFrontEnd = (state: RootState) => state.search.frontend;
export const selectContentType = (state: RootState) => state.search.contentType;
export const selectRelevanceRules = (state: RootState) => state.search.relevance_rules;
export const selectPDPCoPilot = (state: RootState) => state.search.pdp_copilot;
export const selectCart = (state: RootState) => state.search.persisted.cart;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount: number): AppThunk => (
  dispatch,
  getState
) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default searchSlice.reducer;
