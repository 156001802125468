import React, { useState } from 'react';

import { SingleValue } from "react-select";
import FusionFieldDropdown from "./FusionFieldDropdown";
import Slider from '@mui/material/Slider';
import {
  modifyRelevanceRule, RelevanceRuleChangeParam, deleteRelevancyRule
} from '../searchSlice';
import { useAppDispatch } from '../../../app/hooks';
import { BoostQueryState } from '../custom_types';
import FusionFieldValueDropdown from './FusionFieldValueDropdown';
import { Icon } from '@iconify/react';

interface DropdownOption {
  readonly value: string;
  readonly label: string;
}

export function BoostQuery(props:any) {
  const dispatch = useAppDispatch();
  const [boostQueryState, setBoostQueryState] = useState<BoostQueryState>(props.rule);



  const handleBoostChange = (event: Event, newValue: number | number[]) => {
    const newBoostFunctionState = {...boostQueryState}
    newBoostFunctionState.boost = newValue as number;
    setBoostQueryState(newBoostFunctionState);

    const param: RelevanceRuleChangeParam = {
      id: newBoostFunctionState.id,
      ruleState: newBoostFunctionState
    }
    dispatch(modifyRelevanceRule(param));

  };

  const handleFieldSelectionDropdownChange = (selectedOption:SingleValue<DropdownOption>)=>{
    if(selectedOption  != null ) {
      const newBoostFunctionState = {...boostQueryState}
      newBoostFunctionState.fieldName = selectedOption.value;
      setBoostQueryState(newBoostFunctionState);

      const param: RelevanceRuleChangeParam = {
        id: newBoostFunctionState.id,
        ruleState: newBoostFunctionState
      }
      dispatch(modifyRelevanceRule(param));
    }
  };

  const [toggle, setToggle] = useState(props.rule.active);


  const handleEnableDisable = (e:any)=>{
    const newValue = !toggle ;
    setToggle(newValue);


    const newBoostFunctionState = {...boostQueryState}
    newBoostFunctionState.active = newValue;
    setBoostQueryState(newBoostFunctionState);

    const param: RelevanceRuleChangeParam = {
      id: newBoostFunctionState.id,
      ruleState: newBoostFunctionState
    }
    dispatch(modifyRelevanceRule(param));
  };


  const handleFieldValueDropdownChange = (selectedOption:SingleValue<DropdownOption>)=>{
    if(selectedOption  != null ) {
      const newBoostQueryState = {...boostQueryState}
      newBoostQueryState.fieldValue = selectedOption.value;
      setBoostQueryState(newBoostQueryState);

      const param: RelevanceRuleChangeParam = {
        id: newBoostQueryState.id,
        ruleState: newBoostQueryState
      }
      dispatch(modifyRelevanceRule(param));
    }
  };


  const handleDeleteClick = (e:any) =>{
    e.preventDefault();
    const param: RelevanceRuleChangeParam = {
      id: boostQueryState.id,
      ruleState: boostQueryState
    }
    dispatch(deleteRelevancyRule(param));
  }

  return (
    <React.Fragment>
        <div className="row py-1">
          <div className="col-2">
            <div className="form-check form-switch">
            <a href="/" onClick={handleDeleteClick}><Icon icon="carbon:subtract-alt" width="25" /></a>

            Boost Query  
            </div>
          </div>
          <div className="col-3">
            <FusionFieldDropdown onChange={handleFieldSelectionDropdownChange} />
          </div>
          <div className="col-3">
            <FusionFieldValueDropdown fieldName={boostQueryState.fieldName} onChange={handleFieldValueDropdownChange} />
          </div>

          <div className="col-3">
              <Slider 
                disabled={!(boostQueryState.fieldName !== undefined && boostQueryState.fieldName !== '')}
                size="small" 
                value={boostQueryState.boost} 
                onChange={handleBoostChange}   
                valueLabelDisplay="auto"   
                step={5}
                min={0}
                max={1000}/>
          </div>
          <div className="col-1">
            <div className="form-check form-switch">
                
                 <span className="pull-right">
                 <input className="form-check-input" type="checkbox" onChange={handleEnableDisable} checked={toggle} disabled={!(boostQueryState.fieldName !== "")} />
                  </span> 

            </div>
          </div>
        </div>
    </React.Fragment>
  )
}
export default BoostQuery