import React from 'react';
import LeftFilters from "./LeftFilters";
import PaginationControls from "./PaginationControls";
import DropdownSearchOption from "./DropdownSearchOption"
import {
    selectBrowserSearchAppLocation
} from './searchSlice';
import { useAppSelector } from '../../app/hooks';
import ItemsPerPage from "./ItemsPerPage";
import Breadcrumb from './Breadcrumb';
import H1 from './H1'
import Badges from './Badges'
import ContentTypeSelectorTab from './ContentTypeSelectorTab';



function ContentItem(props: any) {
    const doc = props.doc;
    return (
        <div className="col-12">

        <div className="single_product">
                <Badges doc={doc} />


                <div className="product_content list_content">
                    <div className="">
                        <div className="content_name">
                            <h3><a target="_blank" href={doc.url}>{doc.title}</a></h3>
                        </div>
                        <div className="product_desc">
                            <p><a target="_blank" href={doc.url}>{doc.url}</a></p>
                            <p>{doc.extended_description}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}




export function ShowingResults(props: any) {
    const solrResponse = props.solrResponse;
    const numFound = props.numFound;
    const parameters = solrResponse.responseHeader.params;
    const start = (parameters.start) ? (Number(parameters.start)) : 0;

    const rows: number = (parameters.rows) ? (Number(parameters.rows) + 0) : 10;
    const lastResult = start + rows;


    const showingEnd = (numFound < lastResult) ? numFound : lastResult;
    const showingStart: number = (numFound > 0) ? start + 1 : 0;

    return (
        <React.Fragment>
            <div className="page_amount">
                <p>Showing {showingStart}–{showingEnd} of {numFound} results</p>
            </div>
        </React.Fragment>
    )
}

export function ContentSearchResults(props: any) {

    const solrResponse = props.zone.render;
    const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);
    const navigationParams = browserSearchAppLocation.queryParams;

    const itemsPerPageOptions = [12, 24, 48];

    const sortOptions = [
        {
            "label": "Relevance",
            "value": ""
        },
        {
            "label": "Name (A-Z)",
            "value": "title asc"
        },
        {
            "label": "Name (Z-A)",
            "value": "title desc"
        },
        {
            "label": "Price Low to High",
            "value": "price asc"
        },
        {
            "label": "Price High to Low",
            "value": "price desc"
        }
    ];

    const sortValue = navigationParams["sort"] != null ? navigationParams["sort"] : "";


    var docs = [];
    var numFound = 0;
    var groupId = "product_id";
    if (solrResponse && solrResponse.grouped && solrResponse.grouped[groupId]) {
        var groups: any = solrResponse.grouped[groupId].groups;
        groups.forEach((group: any) => {
            var representativeRecord = group.doclist.docs[0];
            docs.push(representativeRecord);
        })
        numFound = solrResponse.grouped[groupId].ngroups;
    }
    else if (solrResponse && solrResponse.response && solrResponse.response.docs) {
        docs = solrResponse.response.docs;
        numFound = solrResponse.response.numFound;
    }




    return (
        <React.Fragment>
            <Breadcrumb solrResponse={solrResponse} />
            {(numFound === 0)
                ?
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="shop_title">
                                <h1>We're sorry.  There were no results found for your search  <span className="searchTerm">{navigationParams.q}</span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
                :
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="shop_title">
                                <H1 solrResponse={solrResponse} />
                                <ContentTypeSelectorTab solrResponse={solrResponse} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <LeftFilters solrResponse={solrResponse} />
                        <div className="col-lg-9 col-md-12">

                            <div className="shop_toolbar_wrapper">
                                <ShowingResults solrResponse={solrResponse} numFound={numFound} />

                                <div className=" niceselect_option">
                                    <form className="select_option" action="#">
                                        <span className="sortByLabel">Sort By:</span>
                                        <DropdownSearchOption
                                            options={sortOptions}
                                            defaultValue={sortValue}
                                            paramName={"sort"}
                                            label="Sort By"
                                        />

                                    </form>


                                </div>

                            </div>

                            <div className="row shop_wrapper grid_list">
                                {
                                    docs.map((doc: any, index: number) => {
                                        return <ContentItem key={doc.id + index} doc={doc} />
                                    })
                                }

                            </div>

                            <div className="shop_toolbar t_bottom">
                                <ItemsPerPage defaultValue={12} options={itemsPerPageOptions} />
                                <PaginationControls solrResponse={solrResponse} numFound={numFound} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}
export default ContentSearchResults