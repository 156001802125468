import React from "react";
import { Param } from "./searchSlice";
import { useAppDispatch } from '../../app/hooks';
import {
    setNavigationParam
} from './searchSlice';
export function PaginationControls(props: any) {

    const solrResponse = props.solrResponse;
    var paginationModel = [];
    var showPageControls: boolean = false;
    const numFound: number = props.numFound;

    if (solrResponse && solrResponse.responseHeader) {
        const parameters = solrResponse.responseHeader.params;
        const rows: number = (parameters.rows) ? (Number(parameters.rows) + 0) : 10;
        const start = (parameters.start) ? (Number(parameters.start)) : 0;
        showPageControls = numFound > rows;


        //total number of pages the results can be divided up to
        const pagesTemp = Math.floor(numFound / rows);
        const pages = (pagesTemp * rows >= numFound) ? pagesTemp :   pagesTemp + 1

        //maximum number of pages to display based on what we want to fit in the UI
        const maxPagesToDisplay = 11;  // the maximum number of pagination links we can display at any given time

        //given the startParameter, the page number we're currently viewing
        const currentPage = Math.round(start / rows) + 1;

        const startPage = getTheStartPageNumber(pages, maxPagesToDisplay, currentPage);

        if (numFound === 0) {
            showPageControls = false;
        }

        /*************Build the model for First ***/
        const firstDisabled = (currentPage === 1);
        const firstModel = {
            label: "<<",
            disabled: firstDisabled,
            start: 0,
            active: false
        }
        paginationModel.push(firstModel);

        const prevStart: number = start - rows;
        const prevDisabled = (prevStart < 0) ;

        /*************Build the model for Previous ***/
        const previousModel = {
            label: "<",
            disabled: prevDisabled,
            start: prevStart,
            active: false
        }
        paginationModel.push(previousModel);

        var pageNumber: number = startPage;
        for (var i = 0; i < maxPagesToDisplay; i++) {
            const startNum = (pageNumber - 1) * rows;
            const labelTxt = pageNumber
            const isActive = (pageNumber === currentPage);

            const pageModel = {
                label: labelTxt,
                disabled: false,
                start: startNum,
                active: isActive
            }
            pageNumber++;

            if(startNum <= numFound){
                paginationModel.push(pageModel);
            }
        }

        /*************Build the model for Next ***/
        const nextStart = start + rows;
        const nextDisabled = (nextStart >= numFound);
        const nextModel = {
            label: ">",
            disabled: nextDisabled,
            start: nextStart,
            active: false
        }
        paginationModel.push(nextModel);

        //Build model for last
        const lastDisabled = (pages === currentPage);
        const lastModel = {
            label: ">>",
            disabled: lastDisabled,
            start:  (pages -1) * rows,
            active: false
        }
        paginationModel.push(lastModel);


    }


    return (
        <React.Fragment>
            {
                (showPageControls) ?
                    <div className="pagination">
                        <ul>
                            {
                                paginationModel.map(m =>
                                    <PaginationLink key={m.label} model={m} />
                                )
                            }
                        </ul>
                    </div>
                    :
                    ''
            }
        </React.Fragment>
    )
}


function PaginationLink(props: any) {
    const model: any = props.model;
    const cssDisabled = (model.disabled) ? " disabled" : "";
    const cssActive = (model.active) ? " current" : "";
    const cssClass = "" + cssDisabled + cssActive;
    const dispatch = useAppDispatch();

    const handleOnclick = (model: any) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        const param: Param = { name: "start", value: model.start }
        dispatch(setNavigationParam(param))
    }

    return (
        <React.Fragment>
            <li className={cssClass}><a href="/" onClick={handleOnclick(model)} >{model.label}</a></li>
        </React.Fragment>
    )
}

export default PaginationControls

function getTheStartPageNumber(pages: number, maxPagesToDisplay: number, currentPage: number) {
    const midPointPosition: number = Math.round(maxPagesToDisplay / 2);
    //case 1: the current page is less than the midpoint, which means it's near the left most edge
    if (currentPage <= midPointPosition) {
        return 1;
    }
    //case 2: the currentpage is near the right most edge
    else if ((currentPage + (midPointPosition - 1)) > pages) {
        return pages - maxPagesToDisplay + 1;
    }
    else {
        //case 3:  the current page is nowhere near the left or right edge
        return currentPage - midPointPosition + 1;
    }
}
