import axios, { AxiosRequestConfig } from "axios";
import { GroupBase, OptionsOrGroups, Props } from "react-select";
import AsyncSelect from 'react-select/async';
import configData from "../../../system_config.json";
import { getCurrentEnvironment } from "../EnvironmentConfiguration";

const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));



export function FusionFieldDropdown<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: Props<Option, IsMulti, Group>) {
  
  
  const fetchFields = (inputValue: string, callback: (options: OptionsOrGroups<Option, Group>) => void) => {
    var params = new URLSearchParams();
    params.append("q", inputValue);

    const urlStr = configDataOfAnyType[getCurrentEnvironment()].fusion.url + '/api/suggestions/collections/' + configDataOfAnyType[getCurrentEnvironment()].fusion.app + '/schema/fields'
    const options: AxiosRequestConfig = {
      url: urlStr,
      method: "get",
      params: params,
      auth: {
        username: 'admin',
        password: 'm2cool2b2',
      },
    };

    axios(options)
      .then((res) => res.data)
      .then((result: any) => {
        console.log("result found " + JSON.stringify(result))
        const options: Option[] = [];

        result.forEach((item: any) => {
          var anOption: any = { label: item.name, value: item.name }
          options.push(anOption);
        })

        callback(options);
      })
      .catch((err) => {
        console.error("Error occurred in querying Fusion: " + err.message);
      });
  }

  return (
    <AsyncSelect
      {... props}
      loadOptions={fetchFields}

    />
  )
}
export default FusionFieldDropdown