import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {selectPDPCoPilot} from './searchSlice'
import ReactJson from 'react-json-view'

export default function ProductDetailsCoPilotMoreInfo() {
    const pdp_copilot = useAppSelector(selectPDPCoPilot)
    var llm_prompt = (pdp_copilot !== null) ? pdp_copilot.llm_prompt : "";
    if(llm_prompt != null){
        llm_prompt = llm_prompt.replace(/\n/g, "\n");
    }

    return(
        (pdp_copilot !== null && Object.keys(pdp_copilot).length > 0 ) ? 
        <>
        <h3 className="pdp_copilot_debug">Product Details Page CoPilot Debug</h3>



        <h4 className="pdp_copilot_debug">
        Product Information
        </h4>  
        <ReactJson src={pdp_copilot.product} collapsed={true} />

        <h4 className="pdp_copilot_debug">
        Search Results from Supporting Documents
        </h4 >  
        <ReactJson src={pdp_copilot.supporting_documents} collapsed={true} />


        <h4 className="pdp_copilot_debug">
        Prompt
        </h4>  

        <textarea className="form-control" value={llm_prompt} rows={100}  />

        </>
        :<></>
    )
}

