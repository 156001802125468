import React from "react";
import FusionRender from "./FusionRender";
import { Helmet } from "react-helmet";
import configData from "../../system_config.json"
import { getCurrentEnvironment } from "./EnvironmentConfiguration"
const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));

export function Home() {

  const titlePrefix = configDataOfAnyType[getCurrentEnvironment()].page_title_prefix;


  return (
    <React.Fragment>
      <section className="shipping_area mb-50">
        <div className="container">
        </div>
      </section>
      <FusionRender templateTypes={["home"]} />
      {/*Sets the Page Title in the <head> section of the Page*/}
      <Helmet>
        <title>{titlePrefix}</title>
      </Helmet>
    </React.Fragment>
  )
}
export default Home