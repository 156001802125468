import React, { useState } from 'react';
import DemoControlPanel from "./DemoControlPanel";
import {
  useLocation, 
  Link
} from "react-router-dom";
import {Helmet} from "react-helmet";
import { useAppSelector } from '../../app/hooks';
import {
  selectBrowserSearchAppLocation
} from './searchSlice';
import configData from "../../system_config.json"
import { getCurrentEnvironment } from "./EnvironmentConfiguration"
import { Icon } from '@iconify/react';

const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));


export function Breadcrumb(props: any) {

  const solrResponse = props.solrResponse;
  const location = useLocation();
  const pathname = location.pathname;
  const pageType = (pathname.startsWith("/search") || pathname.startsWith("/content")) ? "search" : "browse";

  return (
    <React.Fragment>
      {(pageType === "browse")
        ? <BrowseBreadcrumb solrResponse={solrResponse} />
        : <SearchBreadcrumb solrResponse={solrResponse} />  
      }
    </React.Fragment>
  )
}
export default Breadcrumb





function SearchBreadcrumb(props:any) {
  const [showDemoPanel, setShowDemoPanel] = useState(false);
  const solrResponse = props.solrResponse;
  const browserSearchAppLocation = useAppSelector(selectBrowserSearchAppLocation);

  const navigationParams = browserSearchAppLocation.queryParams;
  const titlePrefix = configDataOfAnyType[getCurrentEnvironment()].page_title_prefix;
  const query = (navigationParams.q) ? navigationParams.q : ""
  return (
    <React.Fragment>
    <div className="breadcrumbs_area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb_content">
              <ul>
                <li><Link to="/">home</Link></li>
                <li onClick={() => { setShowDemoPanel(!showDemoPanel) }}>Search
                
                <a href="/" onClick={(e) => {e.preventDefault(); setShowDemoPanel(!setShowDemoPanel) }}><Icon icon="material-symbols:data-info-alert-rounded" width="20"/></a>                        

                </li>
              </ul>
            </div>
          </div>
        </div>
        {(showDemoPanel)
          ?           <DemoControlPanel solrResponse={solrResponse}/>

          : <React.Fragment />
        }
              
      </div>
    </div>
      {/*Sets the Page Title in the <head> section of the Page*/}
      <Helmet>
        <title>{titlePrefix} | Search | {query}</title>
      </Helmet>
    </React.Fragment>

  )
}



function BrowseBreadcrumb(props: any) {
  const solrResponse = props.solrResponse;
  const [showDemoPanel] = useState(false);
  const breadcrumbResponse = (solrResponse.b2b && solrResponse.b2b.breadcrumb) ? solrResponse.b2b.breadcrumb : []
  const titlePrefix = configDataOfAnyType[getCurrentEnvironment()].page_title_prefix;
  const breadcrumbNames = breadcrumbResponse.map((crumb:any) =>{return crumb.name})
  const breadcrumbNamesAsString = breadcrumbNames.join(" | ");

  return (
    <React.Fragment>
    <div className="breadcrumbs_area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb_content">
              <ul>
              <li><Link to="/">home</Link></li>
                {
                  breadcrumbResponse.map((crumb: any, index: number) => {
                    return <Crumb key={crumb.name} crumb={crumb} index={index} size={breadcrumbResponse.length}/>
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        {(showDemoPanel)
          ? <DemoControlPanel solrResponse={solrResponse}/>
          : <React.Fragment />
        }
      </div>
    </div>
      {/*Sets the Page Title in the <head> section of the Page*/}
      <Helmet>
        <title>{titlePrefix} | {breadcrumbNamesAsString}</title>
      </Helmet>
    </React.Fragment>

  )
}

function Crumb(props:any){
  const crumb = props.crumb;
  const last = (props.index === props.size-1);
  return(
    <React.Fragment>
    {(!last)
      ? <li><Link to={crumb.url}>{crumb.name}</Link></li>
      : <li>{crumb.name}</li>
    }
    </React.Fragment>
  )
}
